import { MouseEvent, ReactElement } from "react";

interface IconButtonProps {
  loading?: boolean;
  disabled?: boolean;
  icon: ReactElement;
  type?: "button" | "reset" | "submit";
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  color?: "primary" | "secondary";
  variant?: "contained" | "outlined" | "text";
}

const styles = {
  // Primary
  "primary-text":
    "text-amber-600 hover:text-amber-700 hover:bg-amber-100 focus:bg-amber-100 focus:text-amber-700 disabled:text-neutral-300 disabled:bg-inherit",
  "primary-outlined":
    "text-amber-600 border border-amber-600 hover:bg-amber-100 focus:bg-amber-100 disabled:border-neutral-300 disabled:text-neutral-300 disabled:bg-inherit",
  "primary-contained":
    "bg-amber-600 text-neutral-50 hover:bg-amber-700 focus:ring focus:ring-amber-400 focus:bg-amber-700 disabled:bg-neutral-300",
  // Secondary
  "secondary-text":
    "hover:bg-neutral-200 focus:bg-neutral-200 focus:text-neutral-700 disabled:text-neutral-300 disabled:bg-inherit",
  "secondary-outlined":
    "border border-neutral-600 hover:bg-neutral-200 focus:bg-neutral-200 disabled:text-neutral-300 disabled:bg-inherit disabled:border-neutral-300",
  "secondary-contained":
    "bg-neutral-600 text-neutral-50 hover:bg-neutral-700 focus:ring focus:ring-neutral-400 focus:bg-neutral-700 disabled:bg-neutral-300",
};

function IconButton(props: IconButtonProps) {
  const color = props.color ?? "primary";
  const type = props.variant ?? "contained";

  const classes = styles[`${color}-${type}`];

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (!props.onClick) return;
    props.onClick(event);
  }

  return (
    <button
      onClick={handleClick}
      type={props.type ?? "button"}
      disabled={props.disabled ?? false}
      className={`rounded-full tracking-widest p-4 w-6 h-6 focus:outline-none transition-colors disabled:cursor-not-allowed ${classes} flex items-center justify-center`}
    >
      {props.icon}
    </button>
  );
}

export default IconButton;
