import { useEffect, useMemo } from "react";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Validation
import { useForm } from "react-hook-form";
import { storeFetchValidator } from "validators/store";

// Router
import { Outlet, useNavigate } from "react-router-dom";

// Components
import StoreList from "@components/StoreList";
import StoreFilters from "@components/StoreFilters";
import TextField from "@components/general/TextField";
import IconButton from "@components/general/IconButton";

type FormData = z.infer<typeof storeFetchValidator>;

const Stores = () => {
  const navigate = useNavigate();

  // Persist form values to local storage
  useEffect(() => {
    const storeId = localStorage.getItem("selectedStore");
    if (storeId) navigate(`/stores/${storeId}`);
  }, [navigate]);

  // If there are persisted data, use them
  const defaultFilters = useMemo(() => {
    const storedValues = localStorage.getItem("storeFilters");
    return storedValues
      ? (JSON.parse(storedValues) as FormData)
      : {
          sortBy: "_id",
          sortOrder: "-1",
        };
  }, []);

  const { register, watch, reset, setValue } = useForm<FormData>({
    mode: "onBlur",
    criteriaMode: "all",
    resolver: zodResolver(storeFetchValidator),
    defaultValues: JSON.parse(JSON.stringify(defaultFilters)) as FormData,
  });

  const formValues = watch();

  // Persist form values to local storage
  useEffect(() => {
    localStorage.setItem("storeFilters", JSON.stringify(formValues));
  }, [formValues]);

  const handleFilterChange = (values: FormData) => reset(values);

  const handleSearchReset = () => setValue("search", "");

  const handleAddStoreClick = () => {
    localStorage.removeItem("selectedStore");
    navigate("/stores/add");
  };

  return (
    <div className="max-h-screen h-screen grid grid-cols-[350px_1fr] border-l border-neutral-200/60">
      <div className="bg-white">
        <div className="p-4 border-b border-neutral-300">
          <div className="flex justify-between items-center pb-6">
            <h1>Lista e Dyqaneve</h1>
            <div className="flex gap-2">
              <IconButton
                variant="outlined"
                onClick={handleAddStoreClick}
                icon={<i className="ri-add-line text-xl pl-[2px]" />}
              />
              <StoreFilters
                filters={formValues}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <TextField
            name="search"
            placeholder="Kërko"
            register={register}
            value={formValues.search ?? ""}
            onAppendClick={handleSearchReset}
            append={<i className="ri-close-line text-2xl" />}
          />
        </div>
        <StoreList filters={formValues} />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Stores;
