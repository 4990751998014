/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useId,
  useState,
  useEffect,
  ReactNode,
  MouseEvent,
} from "react";

// Forms
import { FieldError, UseFormRegister } from "react-hook-form";

interface TextFieldProps {
  name: string;
  register: UseFormRegister<any>;

  type?: string;
  error?: FieldError;
  touched?: boolean;
  disabled?: boolean;
  required?: boolean;
  append?: ReactNode;
  readOnly?: boolean;
  prepend?: ReactNode;
  placeholder?: string;
  value?: string | number;
  autoComplete?: "on" | "off";
  onAppendClick?: () => void;
  onPrependClick?: () => void;
  onInput?: (value: string) => void;
  onChange?: (value: string) => void;
}

const TextField: React.FC<TextFieldProps> = props => {
  const inputId = useId();
  const [focused, setFocused] = useState(false);

  let classes = "flex items-center border rounded-md w-full bg-white";

  if (props.touched && props.error) {
    classes += " text-red-500 border-red-500";
  } else {
    if (focused) {
      classes += " text-amber-500 border-amber-500";
    } else {
      classes += " text-neutral-500 border-neutral-300 hover:border-amber-300";
    }
  }

  useEffect(() => {
    function handleFocusState(event: Event) {
      const inputEl = document.getElementById(inputId);
      if (!inputEl) return;
      const ifFocusing =
        event.target && (event.target as HTMLElement).id === inputId;
      if (ifFocusing) {
        inputEl.focus();
        setFocused(true);
      } else {
        inputEl.blur();
        setFocused(false);
      }
    }
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key !== "Tab") return;
      const inputEl = document.getElementById(inputId);
      const focusedElement = document.activeElement;
      if (!inputEl) return;
      if (!focusedElement || focusedElement.id !== inputId) {
        inputEl.blur();
        setFocused(false);
      } else if (focusedElement.id === inputId) {
        inputEl.focus();
        setFocused(true);
      }
    }

    const handleWindowBlur = () => setFocused(false);

    function handleWindowFocus() {
      const focusedElement = document.activeElement;
      if (!focusedElement || focusedElement.id !== inputId) return;
      setFocused(true);
    }

    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    document.addEventListener("keyup", event => handleKeyPress(event));
    document.addEventListener("click", event => handleFocusState(event));

    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      document.removeEventListener("keyup", event => handleKeyPress(event));
      document.removeEventListener("click", event => handleFocusState(event));
    };
  }, [inputId]);

  return (
    <div className="w-full">
      <div className={classes}>
        {/* Prepend */}
        <TextFieldIcon
          icon={props.prepend}
          onClick={props.onPrependClick}
          prepend
        />
        <div className="relative flex-grow">
          <InputField
            id={inputId}
            focused={focused}
            type={props.type}
            name={props.name}
            value={props.value}
            error={props.error}
            touched={props.touched}
            required={props.required}
            disabled={props.disabled}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            onInput={props.onInput}
            register={props.register}
            onChange={props.onChange}
          />
          <FieldPlaceholder
            value={props.value}
            placeholder={props.placeholder}
          />
        </div>
        {/* Append */}
        <TextFieldIcon icon={props.append} onClick={props.onAppendClick} />
      </div>
      <FieldErrors touched={props.touched} error={props.error} />
    </div>
  );
};

interface IconProps {
  icon?: ReactNode;
  prepend?: boolean;
  onClick?: () => void;
}

const TextFieldIcon: React.FC<IconProps> = props => {
  let classes = `h-fit text-center`;

  if (props.prepend) classes += " ml-4 mr-1";
  else classes += " mr-4 ml-1";

  if (props.onClick) classes += " cursor-pointer hover:text-amber-500";
  else classes += " cursor-auto";

  function handleClick(event: MouseEvent) {
    if (!props.onClick) return;
    event.stopPropagation();
    props.onClick();
  }

  if (!props.icon) return null;
  return (
    <div role="button" tabIndex={-2} onClick={handleClick} className={classes}>
      {props.icon}
    </div>
  );
};

export const FieldErrors: React.FC<
  Pick<TextFieldProps, "error" | "touched">
> = props => {
  return (
    <div className="px-3 leading-none pt-1 min-h-[24px]">
      <span className="text-sm text-red-500">
        {props.touched && props.error ? props.error.message : ""}
      </span>
    </div>
  );
};

const FieldPlaceholder: React.FC<
  Pick<TextFieldProps, "placeholder" | "value">
> = props => {
  let classes =
    `absolute left-3 -translate-y-1/2 transition-all` +
    ` bg-white select-none` +
    ` peer-focus:top-0 peer-focus:visible peer-focus:text-sm`;

  if (
    (props.value !== "" && props.value !== null && props.value !== undefined) ||
    (typeof props.value === "number" && !isNaN(props.value))
  )
    classes += " text-sm top-0 visible";
  else classes += " top-1/2 invisible";

  return <div className={classes}>{props.placeholder ?? ""}</div>;
};

type InputProps = Pick<
  TextFieldProps,
  | "register"
  | "name"
  | "value"
  | "type"
  | "required"
  | "disabled"
  | "readOnly"
  | "autoComplete"
  | "onChange"
  | "onInput"
  | "placeholder"
  | "error"
  | "touched"
> & { id: string; focused: boolean };
const InputField: React.FC<InputProps> = props => {
  let classes =
    `peer z-10 appearance-none w-full p-3 rounded-md max-h-10 h-10` +
    ` focus:outline-none focus:placeholder:invisible`;

  if (props.value || props.focused) classes += " placeholder:invisible";
  else classes += " placeholder:visible";

  if (props.touched && props.error)
    classes += " placeholder:text-red-400 caret-red-500 text-red-500";
  else
    classes += " placeholder:text-neutral-400 caret-amber-500 text-neutral-900";

  return (
    <input
      id={props.id}
      className={classes}
      type={props.type ?? "text"}
      disabled={props.disabled ?? false}
      readOnly={props.readOnly ?? false}
      placeholder={props.placeholder ?? ""}
      autoComplete={props.autoComplete ?? "off"}
      {...props.register(props.name, {
        valueAsNumber: props.type === "number",
      })}
    />
  );
};

export default TextField;
