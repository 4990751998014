/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef } from "react";

// tRPC
import trpc from "@utils/trpc";

// Zod
import { z } from "zod";

// Validators
import { transactionFetchValidator } from "validators/transaction";

// Utils
import { formatDate } from "@utils/date";
import { formatAmount } from "@utils/amount";

// Components
import Loading from "@components/general/Loading";

type Filters = z.infer<typeof transactionFetchValidator>;

interface TransactionListProps {
  filters: Filters;
  onTransactionClick: (a: string) => void;
}

const TransactionList: React.FC<TransactionListProps> = props => {
  const {
    data,
    error,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = trpc.useInfiniteQuery(["transaction.paginated-list", props.filters], {
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === 0 ? undefined : allPages.length + 1,
  });

  const lastObserver = useRef<IntersectionObserver>();

  const lastElRef = useCallback(
    (node: HTMLDivElement) => {
      if (lastObserver.current) lastObserver.current.disconnect();
      lastObserver.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage().catch(console.error);
        }
      });
      if (node) lastObserver.current.observe(node);
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  const getRef = (groupIndex: number, transactionIndex: number) => {
    if (!data) return;
    const isLastGroup = groupIndex === data.pages.length - 1;
    const isLastElement =
      transactionIndex === data.pages[groupIndex].length - 1;
    return isLastGroup && isLastElement ? lastElRef : undefined;
  };

  function handleClick(id: string) {
    if (isFetching || isLoading) return;
    props.onTransactionClick(id);
  }

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-col gap-4 py-6">
        <Loading />
        <div className="text-neutral-500">Duke ngarkuar transaksionet ...</div>
      </div>
    );
  if (error)
    return (
      <div className="flex items-center flex-col py-6">
        <h1 className="text-red-500">
          Ndodhi një gabim gjatë ngarkimit të transaksioneve.
        </h1>
      </div>
    );
  if (!data)
    return (
      <div className="flex items-center justify-center flex-col gap-4 py-6">
        <div className="text-neutral-500">
          Asnjë transaksion i rregjistruar për këtë dyqan.
        </div>
      </div>
    );

  return (
    <div>
      {data.pages.map((page, pIndex) =>
        page.map((transaction, tIndex) => (
          <div
            role="button"
            tabIndex={0}
            key={transaction._id}
            ref={getRef(pIndex, tIndex)}
            onClick={() => handleClick(transaction._id)}
            className="border-b border-neutral-200 py-3 cursor-pointer hover:bg-amber-100 px-4 last:rounded-b-md"
          >
            <TransactionData
              link={transaction.link}
              date={transaction.date}
              notes={transaction.notes}
              saleNo={transaction.saleNo}
              amount={transaction.amount}
              type={transaction.type.name}
              color={transaction.type.color}
            />
          </div>
        ))
      )}
      {isFetchingNextPage && (
        <div className="flex justify-center pt-3">
          <Loading />
        </div>
      )}
    </div>
  );
};

interface TransactionDataProps {
  date: string;
  type: string;
  color: string;
  amount: number;
  saleNo?: string;
  notes?: string;
  link?: string;
}

const TransactionData: React.FC<TransactionDataProps> = props => {
  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-2 flex-wrap items-center">
          <LinkIcon link={props.link} type={props.type} />
          <div>{props.type}</div>
          <SaleNumber saleNo={props.saleNo} />
        </div>
        <div style={{ color: props.color }}>{formatAmount(props.amount)}</div>
      </div>
      <TransactionNotes notes={props.notes} />
      <div className="pt-1 text-sm">{formatDate(new Date(props.date))}</div>
    </>
  );
};

const SaleNumber: React.FC<{ saleNo?: string }> = ({ saleNo }) => {
  if (!saleNo) return null;
  return <div className="text-neutral-500">{`#${saleNo}`}</div>;
};

const TransactionNotes: React.FC<{ notes?: string }> = ({ notes }) => {
  if (!notes) return null;
  return <div className="text-neutral-500 text-sm">{`Shënime: ${notes}`}</div>;
};

const LinkIcon: React.FC<{ link?: string; type: string }> = ({
  link,
  type,
}) => {
  if (!link || type === "Likuidim") return null;
  return <i className="ri-link text-green-500" />;
};
export default TransactionList;
