import { useEffect, useMemo, useState } from "react";

// React Router
import { useNavigate, useParams } from "react-router-dom";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// tRPC
import trpc from "@utils/trpc";

// Utilities
import { formatAmount } from "@utils/amount";

// Validation
import { useForm } from "react-hook-form";
import { transactionFetchValidator } from "validators/transaction";

// Components
import Card from "@components/general/Card";
import Button from "@components/general/Button";
import Dropdown from "@components/general/Dropdown";
import IconButton from "@components/general/IconButton";
import EditStoreDialog from "@components/EditStoreDialog";
import ErrorDialog from "@components/general/ErrorDialog";
import TransactionList from "@components/TransactionList";
import ConfirmDialog from "@components/general/ConfirmDialog";
import TransactionFilters from "@components/TransactionFilters";
import SuccessSnackbar from "@components/general/SuccessSnackbar";
import EditTransactionDialog from "@components/EditTransactionDialog";

const dropdownOptions = [
  { text: "Modifiko", value: "edit", icon: "ri-pencil-line" },
  { text: "Fshi", value: "delete", icon: "ri-delete-bin-7-line" },
];

type FormData = z.infer<typeof transactionFetchValidator>;

const StoreInfo = () => {
  const { storeId } = useParams();

  const utils = trpc.useContext();

  const navigate = useNavigate();

  // Store
  const [showEditStore, setShowEditStore] = useState(false);
  const [showDeleteStore, setShowDeleteStore] = useState(false);

  // Transaction
  const [selectedTransactionId, setSelectedTransactionId] = useState("");

  // States
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  // If the user is coming to this menu go to the last selected store
  const localStoreId = localStorage.getItem("selectedStore");
  if (localStoreId && !storeId) navigate(`/stores/${localStoreId}`);

  const {
    error,
    refetch,
    isLoading,
    data: store,
  } = trpc.useQuery(["store.info", { id: storeId as string }], {
    enabled: Boolean(storeId),
  });

  const { isLoading: isDeleting, mutate: deleteStore } = trpc.useMutation(
    ["store.delete"],
    {
      onSuccess: () => handleDeleteSuccess(),
      onError: () => setError("Një problem gjatë fshirjes së dyqanit."),
    }
  );

  // If there are persisted data, use them
  const defaultFilters = useMemo(() => {
    const storedValues = localStorage.getItem("transactionFilters");
    return storedValues
      ? (JSON.parse(storedValues) as FormData)
      : {
          sortBy: "date",
          sortOrder: "-1",
        };
  }, []);

  const { watch, reset, setValue } = useForm<FormData>({
    mode: "onBlur",
    criteriaMode: "all",
    resolver: zodResolver(transactionFetchValidator),
    defaultValues: JSON.parse(JSON.stringify(defaultFilters)) as FormData,
  });

  const formValues = watch();

  // Since filters are persisted each time the user selects a different store update the store in the filters
  if (formValues.store !== storeId) setValue("store", storeId);

  // Persist form values to local storage
  useEffect(() => {
    localStorage.setItem("transactionFilters", JSON.stringify(formValues));
  }, [formValues]);

  function handleOptionSelect(option: string) {
    switch (option) {
      case "edit":
        setShowEditStore(true);
        break;
      case "delete":
        setShowDeleteStore(true);
        break;
    }
  }

  const handleStoreDelete = () =>
    !isDeleting && storeId && deleteStore(storeId);

  const handleDeleteSuccess = async () => {
    setShowDeleteStore(false);
    resetStoredData();
    localStorage.removeItem("selectedStore");
    await utils.invalidateQueries(["store.paginated-list"]);
    await utils.invalidateQueries(["store.list"]);
    await utils.invalidateQueries(["store.name"]);
    await utils.invalidateQueries(["store.in-debt"]);
    await utils.invalidateQueries(["store.history"]);
    navigate("/stores");
  };

  function resetStoredData() {
    // Invalidating transaction form if the store is deleted and used in the form
    const form = localStorage.getItem("transactionForm");
    if (form) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const formData = JSON.parse(form);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (formData.store === storeId) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        formData.store = "";
        localStorage.setItem("transactionForm", JSON.stringify(formData));
      }
    }
    // Invalidating history report if the store is deleted and used in the form
    const history = localStorage.getItem("history-data");
    if (history) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const formData = JSON.parse(history);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (formData.id === storeId) {
        localStorage.removeItem("history-data");
        localStorage.removeItem("selected-report");
      }
    }
  }

  const setError = (message: string) => setErrorText(message);
  const setSuccess = (message: string) => setSuccessText(message);

  const handleAddTransactionClick = () =>
    storeId && navigate(`/transactions/${storeId}`);

  const handleTransactionClick = (event: string) =>
    setSelectedTransactionId(event);

  const handleTransactionsFilterChange = (values: FormData) => reset(values);

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  const closeEditDialog = () => {
    setSelectedTransactionId("");
    localStorage.removeItem("transactionForm");
  };

  if (isLoading)
    return (
      <div className="uppercase text-neutral-700 text-sm flex min-h-screen items-center justify-center">
        Duke ngarkuar të dhënat e dyqanit ...
      </div>
    );
  if (error || !store || !storeId)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-[450px] flex items-center flex-col">
          <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
          <h1 className="text-red-500 pb-6">
            Ndodhi një gabim gjatë ngarkimit të të dhënave.
          </h1>
          <Button
            color="error"
            variant="outlined"
            text="Provo Përsëri"
            onClick={handleRefresh}
            prepend={<i className="ri-restart-line" />}
          />
        </div>
      </div>
    );

  return (
    <>
      <div className="overflow-auto h-screen pt-0 pb-4">
        <div className="sticky top-0 bg-neutral-200 pb-2 pt-4 px-4">
          <div className="flex justify-between items-center">
            <StoreDetails name={store.name} nipt={store.nipt} />
            <Dropdown options={dropdownOptions} onSelect={handleOptionSelect}>
              <div className="border border-neutral-500 rounded-full h-6 w-6 p-4 flex items-center justify-center">
                <i className="ri-more-fill text-xl" />
              </div>
            </Dropdown>
          </div>
          <StoreAddress address={store.address} />
        </div>
        <StoreTotals totals={store.totals} />
        <div className="grid grid-cols-3 gap-4 pt-6 px-4">
          <Card containerClasses="col-span-3 xl:col-span-2">
            <div className="flex justify-between items-center p-4 border-b border-neutral-200">
              <h1 className="text-lg">Transaksionet</h1>
              <div className="flex gap-2">
                <IconButton
                  icon={<i className="ri-add-line text-xl pl-[2px]" />}
                  onClick={handleAddTransactionClick}
                  variant="outlined"
                />
                <TransactionFilters
                  filters={formValues}
                  onChange={handleTransactionsFilterChange}
                />
              </div>
            </div>
            <div className="max-h-96 overflow-auto">
              <TransactionList
                filters={formValues}
                onTransactionClick={handleTransactionClick}
              />
            </div>
          </Card>
        </div>
      </div>
      <ConfirmDialog
        loading={isDeleting}
        open={showDeleteStore}
        onClose={() => setShowDeleteStore(false)}
        onConfirm={handleStoreDelete}
      >
        <div>
          Jeni të sigurtë që doni të fshini dyqanin <b>{store.name}</b>?
        </div>
      </ConfirmDialog>
      {Boolean(selectedTransactionId) && (
        <EditTransactionDialog
          storeId={storeId}
          transactionId={selectedTransactionId}
          open={Boolean(selectedTransactionId)}
          onError={setError}
          onClose={closeEditDialog}
          onSuccess={setSuccess}
        />
      )}
      {showEditStore && (
        <EditStoreDialog
          storeId={storeId}
          open={showEditStore}
          onError={setError}
          onSuccess={setSuccess}
          onClose={() => setShowEditStore(false)}
        />
      )}
      <SuccessSnackbar
        text={successText}
        open={Boolean(successText)}
        onClose={() => setSuccess("")}
      />
      <ErrorDialog
        open={Boolean(errorText)}
        onClose={() => setError("")}
        title="Veprim i Pasuksesshëm!"
      >
        <span>{errorText}</span>
        <br />
        <span>Ju lutemi provni përsëri.</span>
      </ErrorDialog>
    </>
  );
};

interface StoreDetailsProps {
  name: string;
  nipt: string;
}

const StoreDetails: React.FC<StoreDetailsProps> = props => {
  return (
    <div className="flex items-baseline gap-4 flex-wrap">
      <h1 className="text-3xl">{props.name}</h1>
      <div className="text-2xl text-neutral-600">&minus;</div>
      <h3 className="text-2xl text-neutral-600">{props.nipt}</h3>
    </div>
  );
};

const StoreAddress: React.FC<{ address?: string }> = props => {
  if (!props.address) return null;
  return <p className="text-neutral-500 pt-1">{props.address}</p>;
};

interface Total {
  type: string;
  total: number;
}

const StoreTotals: React.FC<{ totals: Total[] }> = props => {
  return (
    <div className="grid grid-cols-3 gap-4 pt-6 px-4">
      {props.totals.map(total => (
        <Card
          key={total.type}
          containerClasses="col-span-3 xl:col-span-1 pb-3 pt-1 px-2"
        >
          <div className="p-2">
            <h1 className="text-lg">{total.type}</h1>
            <div className="text-center pt-6 text-2xl">
              {formatAmount(total.total)}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default StoreInfo;
