/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useId, MouseEvent, useEffect } from "react";
import * as ReactDOM from "react-dom";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  children: ReactElement;
}

const Dialog: React.FC<DialogProps> = ({ onClose, open, children }) => {
  const id = useId();

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") onClose();
    }

    document.addEventListener("keydown", event => handleKeyDown(event));

    return () => {
      document.removeEventListener("keydown", event => handleKeyDown(event));
    };
  }, [open, onClose]);

  const handleInnerClick = (event: MouseEvent<HTMLDialogElement>) =>
    event.stopPropagation();

  return ReactDOM.createPortal(
    <div
      onClick={onClose}
      className={`${
        open ? "" : "hidden"
      } absolute h-screen w-screen bg-neutral-900/50 backdrop-blur-[1px] left-0 top-0 z-10`}
    >
      <dialog
        id={id}
        open={open}
        onClick={handleInnerClick}
        className="border bg-white absolute top-6 rounded-sm"
      >
        {children}
      </dialog>
    </div>,
    document.body
  );
};

export default Dialog;
