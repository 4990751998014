import { Outlet } from "react-router-dom";

// Components
import SideMenu from "@components/SideMenu";

function HomeLayout() {
  return (
    <div className=" bg-neutral-200 min-h-screen flex overflow-hidden">
      <SideMenu />
      <div className="flex-grow">
        <Outlet />
      </div>
    </div>
  );
}

export default HomeLayout;
