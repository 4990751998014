import React, { useMemo, useState, KeyboardEvent } from "react";

// tRPC
import trpc from "@utils/trpc";

// Validation
import { useForm, FieldError } from "react-hook-form";

// Components
import Button from "@components/general/Button";
import Dialog from "@components/general/Dialog";
import Loading from "@components/general/Loading";
import IconButton from "@components/general/IconButton";
import TextField, { FieldErrors } from "@components/general/TextField";

interface StorePickerProps {
  value?: string;
  error?: FieldError;
  onChange: (d: string) => void;
}

const StorePicker: React.FC<StorePickerProps> = props => {
  const [open, setOpen] = useState(false);

  const {
    error,
    isLoading,
    data: storeName,
  } = trpc.useQuery(["store.name", props.value], {
    enabled: Boolean(props.value),
  });

  const toggleDialog = (value: boolean) => setOpen(value);
  const handleSelect = (id: string) => {
    toggleDialog(false);
    props.onChange(id);
  };

  const buttonText = useMemo(() => {
    if (!props.value) return "Zgjidh Dyqanin";
    if (isLoading) return "Duke ngarkuar dyqanin ...";
    if (error) return "Error";
    return storeName ?? "";
  }, [props.value, storeName, isLoading, error]);

  return (
    <>
      <Button
        onClick={() => toggleDialog(true)}
        text={buttonText}
        prepend={<i className="ri-store-2-line" />}
        variant="outlined"
        color="secondary"
        block
      />
      <FieldErrors error={props.error} touched={true} />
      <StorePickerDialog
        open={open}
        value={props.value}
        storeName={storeName}
        onSelect={handleSelect}
        onClose={() => toggleDialog(false)}
      />
    </>
  );
};

interface DialogProps {
  open: boolean;
  value?: string;
  storeName?: string;
  onClose: () => void;
  onSelect: (id: string) => void;
}

const StorePickerDialog: React.FC<DialogProps> = props => {
  const [selectedStore, setSelectedStore] = useState(props.value ?? "");

  const handleConfirmClick = () => props.onSelect(selectedStore);

  const handleSelect = (id: string) => setSelectedStore(id);

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <div className="w-[600px]">
        {/* Title */}
        <section className="flex justify-between items-center border-b border-b-neutral-300 pb-3">
          <h1 className="text-lg">Dyqani</h1>
          <IconButton
            variant="text"
            color="secondary"
            icon={<i className="ri-close-line text-xl text-black" />}
            onClick={() => props.onClose()}
          />
        </section>
        {/* Content */}
        <section>
          <OptionsList selected={selectedStore} onSelect={handleSelect} />
        </section>
        {/* Actions */}
        <section className="flex justify-end gap-4 pt-4 border-t border-t-neutral-300">
          <Button
            text="Mbyll"
            color="secondary"
            variant="text"
            onClick={() => props.onClose()}
          />
          <Button
            text="Zgjidh"
            prepend={<i className="ri-store-2-line" />}
            onClick={handleConfirmClick}
          />
        </section>
      </div>
    </Dialog>
  );
};

interface OptionsListProps {
  selected: string;
  onSelect: (id: string) => void;
}

const OptionsList: React.FC<OptionsListProps> = props => {
  const {
    error,
    refetch,
    isLoading,
    data: stores,
  } = trpc.useQuery(["store.list"]);

  const { watch, register, setValue } = useForm<{
    search: string;
  }>();

  const formValues = watch();

  const filteredStores = useMemo(() => {
    if (!stores) return [];
    const parsedSearch = formValues.search
      ? formValues.search.toLowerCase().trim()
      : "";
    if (!parsedSearch) return stores;
    return stores.filter(store => {
      const name = store.name.toLowerCase();
      const nipt = store.nipt.toLowerCase();
      const address = store.address ? store.address.toLowerCase() : "";
      return [name, address, nipt].some(field => field.includes(parsedSearch));
    });
  }, [stores, formValues.search]);

  const handleSearchReset = () => setValue("search", "");
  const handleSelect = (id: string) => props.onSelect(id);

  const handleKeyDown = (e: KeyboardEvent<HTMLLIElement>, id: string) => {
    if (e.key === "Enter") handleSelect(id);
  };

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-col gap-4 py-12">
        <Loading />
        <div className="text-neutral-500">Duke ngarkuar dyqanet ...</div>
      </div>
    );
  if (error || !Array.isArray(stores))
    return (
      <div className="flex items-center flex-col py-12">
        <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
        <h1 className="text-red-500 pb-6">
          Ndodhi një gabim gjatë ngarkimit të dyqaneve.
        </h1>
        <Button
          color="error"
          variant="outlined"
          text="Provo Përsëri"
          onClick={handleRefresh}
          prepend={<i className="ri-restart-line" />}
        />
      </div>
    );

  return (
    <div className="pt-4">
      <TextField
        name="search"
        placeholder="Kërko"
        register={register}
        value={formValues.search}
        onAppendClick={handleSearchReset}
        append={<i className="ri-close-line text-2xl" />}
      />
      <ul className="max-h-96 overflow-auto">
        {filteredStores.map(store => (
          <li
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            tabIndex={0}
            key={store._id.toString()}
            onKeyDown={e => handleKeyDown(e, store._id.toString())}
            onClick={() => handleSelect(store._id.toString())}
            className={`border-b border-neutral-300 pointer focus:outline-amber-700 ${
              props.selected === store._id.toString()
                ? "bg-amber-500 text-white sticky top-0 bottom-0"
                : "hover:bg-amber-100"
            }`}
          >
            <div className="flex justify-between items-center flex-wrap  px-2 h-16">
              <div>
                <div>{store.name}</div>
                <div
                  className={`${
                    props.selected === store._id.toString()
                      ? "text-white"
                      : "text-neutral-500"
                  } text-sm`}
                >
                  {store.address}
                </div>
              </div>
              <span>{store.nipt}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StorePicker;
