import React from "react";

// Components
import Dialog from "@components/general/Dialog";
import Button from "@components/general/Button";
import IconButton from "@components/general/IconButton";

interface ConfirmDialogProps {
  open: boolean;
  loading?: boolean;
  children: React.ReactElement;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = props => {
  const handleClose = () => !props.loading && props.onClose();
  const handleConfirm = () => !props.loading && props.onConfirm();
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div className="w-[500px]">
        {/* Title */}
        <section className="flex justify-between pb-3">
          <h1 className="text-lg">Konfirmo</h1>
          <IconButton
            icon={<i className="ri-close-line text-xl" />}
            onClick={props.onClose}
            color="secondary"
            variant="text"
          />
        </section>
        {/* Content */}
        <section>{props.children}</section>
        {/* Actions */}
        <section className="border-t border-neutral-300 flex justify-end gap-4 pt-3 mt-3">
          <Button
            text="Anullo"
            variant="text"
            color="secondary"
            disabled={props.loading}
            onClick={handleClose}
          />
          <Button
            text="Konfirmo"
            prepend={<i className="ri-check-line" />}
            loading={props.loading}
            onClick={handleConfirm}
          />
        </section>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
