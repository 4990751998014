import React, { useState, FormEvent, useEffect } from "react";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Validation
import { historyInput, historyOutput, inDebtInput } from "validators/store";

// Utils
import { formatDate } from "@utils/date";
import { formatAmount } from "@utils/amount";
import {
  generateHistoryExcel,
  generateHistoryPdf,
  generateInDebtPdf,
  generateInDebtExcel,
} from "@utils/report";

// tRPC
import trpc from "@utils/trpc";

// Form
import { useForm } from "react-hook-form";

// Components
import Card from "@components/general/Card";
import Button from "@components/general/Button";
import StorePicker from "@components/StorePicker";
import DatePicker from "@components/general/date/DatePicker";
import CheckboxGroup from "@components/general/CheckboxGroup";

type ReportTypes = "in-debt" | "history" | undefined;

interface ReportSelectorProps {
  selected: ReportTypes;
  onSelect: (type: ReportTypes) => void;
}

type InDebtFormData = z.infer<typeof inDebtInput>;

type HistoryData = z.infer<typeof historyOutput>;
type HistoryFormData = z.infer<typeof historyInput>;

const historyDefaults = {
  id: undefined,
  date: {
    to: undefined,
    from: undefined,
  },
};

const inDebtDefaults = {
  types: [],
};

const Reports = () => {
  const [selected, setSelected] = useState<ReportTypes>(() => {
    const storedReport = localStorage.getItem("selected-report");
    if (storedReport) return storedReport as ReportTypes;
  });

  useEffect(() => {
    if (selected) localStorage.setItem("selected-report", selected);
    else {
      localStorage.removeItem("selected-report");
      localStorage.removeItem("history-data");
      localStorage.removeItem("in-debt-data");
    }
  }, [selected]);

  if (!selected)
    return <ReportSelector selected={selected} onSelect={setSelected} />;

  switch (selected) {
    case "in-debt":
      return <InDebtReport onBack={() => setSelected(undefined)} />;
    case "history":
      return <HistoryReport onBack={() => setSelected(undefined)} />;
  }
};

const ReportSelector: React.FC<ReportSelectorProps> = props => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-96 flex flex-col items-center">
        {/* In debt stores */}
        <Button
          onClick={() => props.onSelect("in-debt")}
          text="Raport i dyqaneve debitorë"
          prepend={<i className="ri-hand-coin-line" />}
          block
        />
        <div className="border-t border-amber-400 my-6 w-32"></div>
        <Button
          onClick={() => props.onSelect("history")}
          text="Raport Historik"
          variant="outlined"
          prepend={<i className="ri-time-line" />}
          block
        />
      </div>
    </div>
  );
};

interface ReportProps {
  onBack: () => void;
}

const InDebtReport: React.FC<ReportProps> = props => {
  const [data, setData] = useState<InDebtFormData | undefined>(() => {
    const localData = localStorage.getItem("in-debt-data");
    if (localData) return JSON.parse(localData) as InDebtFormData;
  });

  const {
    error,
    refetch,
    isLoading,
    data: report,
  } = trpc.useQuery(["store.in-debt", data as InDebtFormData], {
    staleTime: 300000, // 5 minutes
    enabled: Boolean(data),
  });

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  const generatePdf = () => report && generateInDebtPdf(report);
  const generateExcel = () => report && generateInDebtExcel(report);

  const generateReport = (data: InDebtFormData) => {
    localStorage.setItem("in-debt-data", JSON.stringify(data));
    setData(data);
  };

  if (error)
    return (
      <div className="flex items-center flex-col justify-center min-h-screen">
        <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
        <h1 className="text-red-500 pb-6">
          Ndodhi një gabim gjatë ngarkimit të raportit.
        </h1>
        <div className="flex gap-4">
          <Button
            onClick={props.onBack}
            prepend={<i className="ri-arrow-left-s-line" />}
            variant="outlined"
            color="secondary"
            text="Mbrapa"
          />
          <Button
            color="error"
            variant="outlined"
            text="Provo Përsëri"
            onClick={handleRefresh}
            prepend={<i className="ri-restart-line" />}
          />
        </div>
      </div>
    );
  if (report && data)
    return (
      <ReportView
        onBack={props.onBack}
        onPdfClick={generatePdf}
        onExcelClick={generateExcel}
      >
        <div
          className="flex pt-12 justify-center overflow-auto "
          style={{ height: "calc(100vh - 60px)" }}
        >
          {/* Page */}
          <div className="bg-white w-10/12 max-w-3xl min-h-[1250px] h-fit p-8 mb-12">
            <h1 className="text-xl text-center uppercase">
              Lista e Debitorëve
            </h1>
            <div className="py-12"></div>
            <table className="w-full">
              <thead className="border-b border-neutral-700">
                <tr>
                  <th className="text-left text-neutral-700 font-semibold">
                    Emri
                  </th>
                  <th className="text-left text-neutral-700 font-semibold">
                    NIPT
                  </th>
                  <th className="text-right text-neutral-700 font-semibold">
                    Debi
                  </th>
                </tr>
              </thead>
              <tbody>
                {report.map((element, index) => (
                  <tr key={index}>
                    <td>{element.name}</td>
                    <td>{element.nipt ?? "&minus;"}</td>
                    <td className="text-right">
                      {formatAmount(element.debtAmount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ReportView>
    );

  return (
    <div className="min-h-screen flex items-center justify-center">
      <InDebtReportForm
        loading={isLoading}
        onBack={props.onBack}
        onSubmit={generateReport}
      />
    </div>
  );
};

const HistoryReport: React.FC<ReportProps> = props => {
  const [data, setData] = useState<HistoryFormData | undefined>(() => {
    const localData = localStorage.getItem("history-data");
    if (localData) return JSON.parse(localData) as HistoryFormData;
  });

  const {
    error,
    refetch,
    isLoading,
    data: report,
  } = trpc.useQuery(["store.history", data as HistoryFormData], {
    staleTime: 300000, // 5 minutes
    enabled: Boolean(data),
  });

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  const generateReport = (data: HistoryFormData) => {
    localStorage.setItem("history-data", JSON.stringify(data));
    setData(data);
  };

  const generatePdf = () => {
    if (!report || !data || !data?.date) return;
    generateHistoryPdf(report, data.date.from, data.date.to);
  };

  const generateExcel = () => report && generateHistoryExcel(report);

  if (error)
    return (
      <div className="flex items-center flex-col justify-center min-h-screen">
        <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
        <h1 className="text-red-500 pb-6">
          Ndodhi një gabim gjatë ngarkimit të raportit.
        </h1>
        <div className="flex gap-4">
          <Button
            onClick={props.onBack}
            prepend={<i className="ri-arrow-left-s-line" />}
            variant="outlined"
            color="secondary"
            text="Mbrapa"
          />
          <Button
            color="error"
            variant="outlined"
            text="Provo Përsëri"
            onClick={handleRefresh}
            prepend={<i className="ri-restart-line" />}
          />
        </div>
      </div>
    );

  if (report && data)
    return (
      <ReportView
        onBack={props.onBack}
        onPdfClick={generatePdf}
        onExcelClick={generateExcel}
      >
        <div
          className="flex pt-12 justify-center overflow-auto "
          style={{ height: "calc(100vh - 60px)" }}
        >
          {/* Page */}
          <HistoryReportWebView report={report} data={data} />
        </div>
      </ReportView>
    );

  return (
    <div className="min-h-screen flex items-center justify-center">
      <HistoryReportForm
        loading={isLoading}
        onBack={props.onBack}
        onSubmit={generateReport}
      />
    </div>
  );
};

type HistoryReportFormProps = Pick<ReportProps, "onBack"> & {
  onSubmit: (data: HistoryFormData) => void;
  loading: boolean;
};

const HistoryReportForm: React.FC<HistoryReportFormProps> = props => {
  const localData = localStorage.getItem("history-data");

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<HistoryFormData>({
    defaultValues: localData
      ? (JSON.parse(localData) as HistoryFormData)
      : historyDefaults,
    criteriaMode: "all",
    resolver: zodResolver(historyInput),
  });

  const formValues = watch();

  const changeStore = (value: string) => setValue("id", value);
  const changeTo = (value: string) => setValue("date.to", value);
  const changeFrom = (value: string) => setValue("date.from", value);

  const resetForm = () => reset({ ...historyDefaults });

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    handleSubmit(props.onSubmit)().catch(err => console.error(err));
  }

  return (
    <Card containerClasses="p-6 pt-9 w-[424px]">
      <form onSubmit={onSubmit} noValidate>
        <h1 className="text-lg pb-8 text-center uppercase ">
          Plotëso detajet e raportit
        </h1>
        <StorePicker
          error={errors.id}
          value={formValues.id}
          onChange={changeStore}
        />
        <div>
          <span className="uppercase text-sm">Nga data</span>
          <div className="pt-2">
            <DatePicker
              error={errors.date?.from}
              value={formValues.date?.from}
              onChange={changeFrom}
            />
          </div>
        </div>
        <div>
          <span className="uppercase text-sm">Deri në datën</span>
          <div className="pt-2">
            <DatePicker
              error={errors.date?.to}
              value={formValues.date?.to}
              onChange={changeTo}
            />
          </div>
        </div>
        <div className="flex justify-between items-center gap-4 pt-8 mt-3 border-t border-neutral-700">
          <Button
            disabled={props.loading}
            onClick={props.onBack}
            prepend={<i className="ri-arrow-left-s-line" />}
            variant="outlined"
            color="secondary"
            text="Mbrapa"
            block
          />
          <Button
            loading={props.loading}
            prepend={<i className="ri-check-double-line" />}
            text="Gjenero"
            type="submit"
            block
          />
        </div>

        <div className="pt-6">
          <Button
            onClick={resetForm}
            disabled={props.loading}
            prepend={<i className="ri-restart-line" />}
            color="secondary"
            text="Rifillo"
            variant="text"
            block
          />
        </div>
      </form>
    </Card>
  );
};
interface HistoryReportWebViewProps {
  report: HistoryData;
  data: HistoryFormData;
}

const HistoryReportWebView: React.FC<HistoryReportWebViewProps> = ({
  report,
  data,
}) => {
  return (
    <div className="bg-white w-10/12 max-w-3xl min-h-[1250px] h-fit p-8 mb-12">
      <div className="flex justify-between items-start">
        {/* Store Details */}
        <div>
          <h1 className="text-2xl pb-2">{`${report.name}`}</h1>
          <div className="text-gray-600 text-sm">{`NIPT: ${report.nipt}`}</div>
          {report.address && (
            <p className="text-gray-600 text-sm">{`Adresa: ${report.address}`}</p>
          )}
        </div>
        {/* Report Details */}
        {data && data.date && data.date.from && data.date.to ? (
          <span>{`${formatDate(new Date(data.date.from))} - ${formatDate(
            new Date(data.date.to)
          )}`}</span>
        ) : null}
      </div>
      {/* Transactions */}
      <div className="pt-12">
        {/* List of Transactions */}
        <div className="pt-12">
          <table className="w-full">
            <thead className="border-b border-neutral-700">
              <tr>
                <th className="text-left text-neutral-700 font-semibold">
                  Data
                </th>
                <th className="text-left text-neutral-700 font-semibold">
                  Veprimi
                </th>
                <th className="text-right text-neutral-700 font-semibold">
                  Shuma
                </th>
              </tr>
            </thead>
            <tbody>
              {report.transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{formatDate(new Date(transaction.date))}</td>
                  <td>{transaction.type}</td>
                  <td className="text-right">
                    {formatAmount(transaction.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface ReportViewProps {
  children: React.ReactNode;
  onBack: () => void;
  onPdfClick: () => void;
  onExcelClick: () => void;
}

const ReportView: React.FC<ReportViewProps> = props => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const downloadPdf = () => {
    setLoadingPdf(true);
    props.onPdfClick();
    setLoadingPdf(false);
  };
  const downloadExcel = () => {
    setLoadingExcel(true);
    props.onExcelClick();
    setLoadingExcel(false);
  };

  return (
    <div className="min-h-screen overflow-auto">
      <div className="sticky top-0 bg-white p-2 border-l border-neutral-200/60 shadow-sm flex justify-between items-center">
        <Button
          onClick={props.onBack}
          prepend={<i className="ri-arrow-left-s-line" />}
          variant="outlined"
          color="secondary"
          text="Mbrapa"
        />
        <div className="flex gap-4 items-center">
          <Button
            disabled={loadingExcel}
            loading={loadingPdf}
            onClick={downloadPdf}
            prepend={<i className="ri-file-pdf-line" />}
            text="Shkarko PDF"
            color="primary"
          />
          <Button
            loading={loadingExcel}
            disabled={loadingPdf}
            onClick={downloadExcel}
            prepend={<i className="ri-file-excel-2-line" />}
            text="Shkarko Excel"
            variant="outlined"
            color="primary"
          />
        </div>
      </div>
      {props.children}
    </div>
  );
};

type InDebtReportFormProps = Pick<ReportProps, "onBack"> & {
  onSubmit: (data: InDebtFormData) => void;
  loading: boolean;
};

const InDebtReportForm: React.FC<InDebtReportFormProps> = props => {
  const localData = localStorage.getItem("in-debt-data");

  const { data: types } = trpc.useQuery(["types.store"]);

  const { watch, reset, setValue, register, handleSubmit } =
    useForm<InDebtFormData>({
      defaultValues: localData
        ? (JSON.parse(localData) as InDebtFormData)
        : inDebtDefaults,
      criteriaMode: "all",
      resolver: zodResolver(inDebtInput),
    });

  const formValues = watch();

  const resetForm = () => reset({ ...inDebtDefaults });

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    handleSubmit(props.onSubmit)().catch(err => console.error(err));
  }

  function handleTypeChange(value: string) {
    const types = formValues.types ?? [];
    const index = types.findIndex(val => val === value);
    let newValue: string[] = [];
    if (index !== -1) {
      const temp = [...types];
      temp.splice(index, 1);
      newValue = [...temp];
    } else newValue = [...types, value];
    setValue("types", newValue, { shouldTouch: true });
  }

  return (
    <Card containerClasses="p-6 pt-9 w-[424px]">
      <form onSubmit={onSubmit} noValidate>
        <div className="pb-4">
          <h1 className="text-lg font-semibold">Përfshi këto tipe:</h1>
          <span className="text-neutral-600 text-sm">
            Nësë asnjë tip nuk është i zgjedhur automatikisht përfshihen të
            gjitha tipet.
          </span>
        </div>
        {types ? (
          <CheckboxGroup
            name="types"
            register={register}
            values={formValues.types ?? []}
            options={types.map(type => ({
              text: type.name,
              value: type._id.toString(),
            }))}
            onEnter={handleTypeChange}
          />
        ) : null}
        <div className="flex justify-between items-center gap-4 pt-8 mt-3">
          <Button
            disabled={props.loading}
            onClick={props.onBack}
            prepend={<i className="ri-arrow-left-s-line" />}
            variant="outlined"
            color="secondary"
            text="Mbrapa"
            block
          />
          <Button
            loading={props.loading}
            prepend={<i className="ri-check-double-line" />}
            text="Gjenero"
            type="submit"
            block
          />
        </div>

        <div className="pt-6">
          <Button
            onClick={resetForm}
            disabled={props.loading}
            prepend={<i className="ri-restart-line" />}
            color="secondary"
            text="Rifillo"
            variant="text"
            block
          />
        </div>
      </form>
    </Card>
  );
};

export default Reports;
