import React, { ReactNode, MouseEvent } from "react";

interface ButtonProps {
  text?: string;
  block?: boolean;
  loading?: boolean;
  disabled?: boolean;
  append?: ReactNode;
  prepend?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "reset" | "submit";
  // Variants
  color?: "primary" | "secondary" | "error";
  variant?: "contained" | "outlined" | "text";
}

const styles = {
  // Primary
  "primary-text":
    "text-amber-600 hover:text-amber-700 hover:bg-amber-100 focus:bg-amber-100 focus:text-amber-700 disabled:text-neutral-300 disabled:bg-inherit",
  "primary-outlined":
    "text-amber-600 border border-amber-600 hover:bg-amber-100 focus:bg-amber-100 disabled:border-neutral-300 disabled:text-neutral-300 disabled:bg-inherit",
  "primary-contained":
    "bg-amber-600 text-neutral-50 hover:bg-amber-700 focus:ring focus:ring-amber-400 focus:bg-amber-700 disabled:bg-neutral-300",
  // Secondary
  "secondary-text":
    "hover:bg-neutral-200 focus:bg-neutral-200 focus:text-neutral-700 disabled:text-neutral-300 disabled:bg-inherit",
  "secondary-outlined":
    "border border-neutral-600 hover:bg-neutral-200 focus:bg-neutral-200 disabled:text-neutral-300 disabled:bg-inherit disabled:border-neutral-300",
  "secondary-contained":
    "bg-neutral-600 text-neutral-50 hover:bg-neutral-700 focus:ring focus:ring-neutral-400 focus:bg-neutral-700 disabled:bg-neutral-300",
  // Error
  "error-text":
    "text-red-600 hover:text-red-700 hover:bg-red-100 focus:bg-red-100 focus:text-red-700 disabled:text-red-300 disabled:bg-inherit",
  "error-outlined":
    "text-red-600 border border-red-600 hover:bg-red-100 focus:bg-red-100 disabled:border-red-300 disabled:text-red-300 disabled:bg-inherit",
  "error-contained":
    "bg-red-600 text-red-50 hover:bg-red-700 focus:ring focus:ring-red-400 focus:bg-red-700 disabled:bg-red-300",
};

const Button: React.FC<ButtonProps> = props => {
  const color = props.color ?? "primary";
  const type = props.variant ?? "contained";

  const classes = styles[`${color}-${type}`];

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (!props.onClick) return;
    props.onClick(event);
  }

  return (
    <button
      onClick={handleClick}
      className={`${
        props.block ? "w-full justify-center" : "w-fit"
      } select-none rounded-md text-sm tracking-widest px-3 py-2.5 focus:outline-none transition-colors disabled:cursor-not-allowed relative ${classes} flex gap-2`}
      type={props.type ?? "button"}
      disabled={props.disabled ?? false}
    >
      <ButtonIcon icon={props.prepend} loading={props.loading} />
      <span className="uppercase">{props.text}</span>
      <ButtonIcon icon={props.append} />
    </button>
  );
};

interface IconProps {
  icon?: ReactNode;
  loading?: boolean;
}

const ButtonIcon: React.FC<IconProps> = props => {
  if (props.loading) return <Loading show={props.loading} />;
  else if (props.icon) return <div>{props.icon}</div>;
  else return null;
};

interface LoadingProps {
  show?: boolean;
}

const Loading: React.FC<LoadingProps> = props => {
  if (!props.show) return null;
  return (
    <div className="animate-spin">
      <i className="ri-loader-5-line"></i>
    </div>
  );
};

export default Button;
