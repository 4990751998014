import { useState } from "react";

// Components
import Card from "@components/general/Card";
import ErrorDialog from "@components/general/ErrorDialog";
import TransactionForm from "@components/TransactionForm";
import SuccessSnackbar from "@components/general/SuccessSnackbar";

function Transactions() {
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [success, setSuccess] = useState({
    show: false,
    message: "",
  });

  const closeError = () => setError({ show: false, message: "" });
  const closeSuccess = () => setSuccess({ show: false, message: "" });

  const handleError = (message: string) =>
    setError({ show: true, message: message });
  const handleSuccess = (message: string) =>
    setSuccess({ show: true, message: message });

  return (
    <>
      <div className="overflow-auto w-full max-w-[600px] max-h-screen p-4">
        <Card containerClasses="p-3">
          <div className="px-2 py-3">
            <TransactionForm onError={handleError} onSuccess={handleSuccess} />
          </div>
        </Card>
      </div>
      <SuccessSnackbar
        open={success.show}
        onClose={closeSuccess}
        text={success.message}
      />
      <ErrorDialog
        open={error.show}
        onClose={closeError}
        title="Veprim i Pasuksesshëm!"
      >
        <span>{error.message}</span>
        <br />
        <span>Ju lutemi provni përsëri.</span>
      </ErrorDialog>
    </>
  );
}

export default Transactions;
