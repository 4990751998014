const months = [
  { name: "Janar", abbr: "Jan" },
  { name: "Shkurt", abbr: "Shk" },
  { name: "Mars", abbr: "Mar" },
  { name: "Prill", abbr: "Pri" },
  { name: "Maj", abbr: "Maj" },
  { name: "Qershor", abbr: "Qer" },
  { name: "Korrik", abbr: "Korr" },
  { name: "Gusht", abbr: "Gusht" },
  { name: "Shtator", abbr: "Shta" },
  { name: "Tetor", abbr: "Tet" },
  { name: "Nëntor", abbr: "Nën" },
  { name: "Dhjetor", abbr: "Dhje" },
];

const days = [
  { name: "E Hënë", abbr: "Hën" },
  { name: "E Martë", abbr: "Mar" },
  { name: "E Mërkurë", abbr: "Mër" },
  { name: "E Enjte", abbr: "Enj" },
  { name: "E Premte", abbr: "Pre" },
  { name: "E Shtunë", abbr: "Sht" },
  { name: "E Dielë", abbr: "Die" },
];

function formatDate(date: Date, format = "short"): string {
  const valid = isDateValid(date);
  if (!valid) return "";

  const year = date.getFullYear();
  const month = months[date.getMonth()].name;
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  let result = "";
  if (format === "short") result = `${day} ${month} ${year}`;
  else result = `${day} ${month} ${year} ${hours}:${minutes}`;
  return result;
}

function isDateValid(date: Date): boolean {
  let result = false;
  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date.getTime())
  )
    result = true;
  return result;
}

// Return the month day and year of a given date string in locale time
function getDateValues(date: string): {
  year: number;
  month: number;
  day: number;
} {
  const dateObj = new Date(date);
  if (isDateValid(dateObj) === false) throw new Error("Invalid date");
  return {
    day: dateObj.getDate(),
    month: dateObj.getMonth(),
    year: dateObj.getFullYear(),
  };
}

function getStartOfDay(date: string) {
  const dateObj = new Date(date);
  return new Date(dateObj.setUTCHours(0, 0, 0, 0)).toISOString();
}

function getEndOfDay(date: string) {
  const dateObj = new Date(date);
  return new Date(dateObj.setUTCHours(23, 59, 59, 999)).toISOString();
}

export {
  days,
  months,
  formatDate,
  isDateValid,
  getDateValues,
  getStartOfDay,
  getEndOfDay,
};
