import React, { ReactNode } from "react";

interface PropsInterface {
  children: ReactNode;
  containerClasses?: string;
}

const Card: React.FC<PropsInterface> = props => {
  const classes = `${
    props.containerClasses ?? ""
  } bg-neutral-50 rounded-md shadow-md`.trim();
  return <div className={classes}>{props.children}</div>;
};

export default Card;
