import { ReactElement } from "react";

// Router
import { Route, Routes } from "react-router-dom";

// Other
import RedirectChecker from "./RedirectChecker";

// Layouts
import AppLayout from "@layouts/AppLayout";
import AuthLayout from "@layouts/AuthLayout";

// Views
import Home from "@views/Home";
import Login from "@views/Login";
import Stores from "@views/Stores";
import Reports from "@views/Reports";
import AddStore from "@views/AddStore";
import UserProfile from "@views/UserProfile";
import StoreInfo from "@components/StoreInfo";
import Transactions from "@views/Transactions";

interface Route {
  id: number;
  isPublic?: boolean;
  path?: string;
  element: ReactElement;
  children?: ChildRoute[];
}

interface ChildRoute {
  id: number;
  path?: string;
  index: boolean;
  element: ReactElement;
  children?: ChildRoute[];
}

const ROUTES: Route[] = [
  {
    id: 1,
    isPublic: true,
    element: <AuthLayout />,
    children: [
      {
        id: 2,
        path: "/",
        index: false,
        element: <Login />,
      },
    ],
  },
  {
    id: 3,
    element: <AppLayout />,
    children: [
      {
        id: 4,
        path: "home",
        index: false,
        element: <Home />,
      },
    ],
  },
  {
    id: 5,
    element: <AppLayout />,
    children: [
      {
        id: 6,
        index: false,
        path: "transactions",
        element: <Transactions />,
      },
      {
        id: 6,
        index: false,
        path: "transactions/:storeId",
        element: <Transactions />,
      },
    ],
  },
  {
    id: 7,
    element: <AppLayout />,
    children: [
      {
        id: 8,
        path: "stores",
        index: false,
        element: <Stores />,
        children: [
          {
            id: 9,
            path: "",
            index: true,
            element: (
              <div className="p-4 uppercase text-neutral-700 text-sm flex items-center justify-center min-h-screen">
                Zgjidh një dyqan për të shfaqur detajet
              </div>
            ),
          },
          {
            id: 10,
            index: false,
            path: "add",
            element: <AddStore />,
          },
          {
            id: 10,
            index: false,
            path: ":storeId",
            element: <StoreInfo />,
          },
        ],
      },
    ],
  },
  {
    id: 11,
    element: <AppLayout />,
    children: [
      {
        id: 12,
        index: false,
        path: "reports",
        element: <Reports />,
      },
    ],
  },
  {
    id: 13,
    element: <AppLayout />,
    children: [
      {
        id: 14,
        index: false,
        path: "profile",
        element: <UserProfile />,
      },
    ],
  },
];

function Router() {
  return (
    <Routes>
      {ROUTES.map(parent => (
        <Route
          key={parent.id}
          path={parent.path}
          element={
            <RedirectChecker isPublic={parent.isPublic}>
              {parent.element}
            </RedirectChecker>
          }
        >
          {parent.children
            ? parent.children.map(child => (
                <Route
                  index={child.index}
                  key={child.id}
                  path={child.path}
                  element={child.element}
                >
                  {child.children
                    ? child.children.map(grandChild => (
                        <Route
                          key={grandChild.id}
                          path={grandChild.path}
                          index={grandChild.index}
                          element={grandChild.element}
                        />
                      ))
                    : null}
                </Route>
              ))
            : null}
        </Route>
      ))}
    </Routes>
  );
}

export default Router;
