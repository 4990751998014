/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";

// tRPC
import trpc from "@utils/trpc";

// React router
import { useNavigate } from "react-router-dom";

// Components
import MenuItem from "@components/MenuItem";
import Avatar from "@components/general/Avatar";
import ErrorDialog from "@components/general/ErrorDialog";

const menuItems = [
  {
    url: "/home",
    text: "Paneli",
    icon: <i className="ri-home-line" />,
  },
  {
    url: "/transactions",
    text: "Transaksionet",
    icon: <i className="ri-exchange-dollar-line" />,
  },
  {
    url: "/stores",
    text: "Dyqanet",
    icon: <i className="ri-store-2-line" />,
  },
  {
    url: "/reports",
    text: "Raporte",
    icon: <i className="ri-file-text-line" />,
  },
  // {
  //   url: "/statistics",
  //   text: "Statisktika",
  //   icon: <i className="ri-line-chart-line" />,
  // },
];

function SideMenu() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpenState = () => setIsOpen(prev => !prev);

  const classes = isOpen ? "w-64" : "w-14";

  return (
    <nav
      className={`${classes} transition-[width] flex-shrink-0 relative h-screen overflow-y-auto overflow-x-hidden bg-white shadow-lg flex flex-col justify-between list-none`}
    >
      <UserProfile open={isOpen} onClick={toggleOpenState} />
      <section>
        <ol>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              url={item.url}
              dense={!isOpen}
              icon={item.icon}
              text={item.text}
            />
          ))}
        </ol>
      </section>
      <div></div>
      <LogoutSection open={isOpen} />
    </nav>
  );
}

interface UserProfileProps {
  open: boolean;
  onClick: () => void;
}

function UserProfile(props: UserProfileProps) {
  if (props.open)
    return (
      <section className="flex flex-col items-center w-full">
        <MenuItem
          icon={<i className="ri-arrow-left-circle-line" />}
          onClick={props.onClick}
        />
        <div className="flex flex-col items-center w-full focus:outline-amber-500 focus:text-amber-500">
          <Avatar />
          <h1 className="font-medium pt-2">Vilson Dervishaj</h1>
          <h1 className="text-sm text-gray-400">Sony.a.d</h1>
        </div>
      </section>
    );
  else
    return (
      <section className="w-full h-[200px]">
        <MenuItem
          icon={<i className="ri-arrow-right-circle-line" />}
          onClick={props.onClick}
          dense
        />
        <MenuItem
          text="e"
          url="/profile"
          icon={<i className="ri-user-line" />}
          dense
        />
      </section>
    );
}

interface LogoutSectionProps {
  open: boolean;
}

function LogoutSection(props: LogoutSectionProps) {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const { mutate: logout } = trpc.useMutation(["auth.logout"], {
    onSuccess: () => handleSuccess(),
    onError: () => setError(true),
  });

  function handleSuccess() {
    localStorage.clear();
    navigate("/", { replace: true });
  }

  return (
    <section className="w-full border-t border-neutral-300">
      <MenuItem
        dense={!props.open}
        onClick={() => logout()}
        text="Dil nga aplikacioni"
        icon={<i className="ri-logout-box-line list-none" />}
      />
      <ErrorDialog
        open={error}
        onClose={() => setError(false)}
        title="Veprim i Pasuksesshëm!"
      >
        <span>Ndodhi një problem gjatë daljes nga aplikacioni!</span>
        <br />
        <span>Ju lutemi provni përsëri.</span>
      </ErrorDialog>
    </section>
  );
}

export default SideMenu;
