import React, { FormEvent, useEffect, useMemo } from "react";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// tRPC
import trpc from "@utils/trpc";

// Validation
import { FieldError, useForm, UseFormRegister } from "react-hook-form";
import { addStoreValidator } from "validators/store";

// Components
import Button from "@components/general/Button";
import TextField from "@components/general/TextField";
import IconButton from "@components/general/IconButton";
import RadioSelect from "@components/general/RadioSelect";

type FormData = z.infer<typeof addStoreValidator>;

const defaults = {
  name: "",
  nipt: "",
  type: "",
  address: "",
};

interface StoreFormProps {
  storeId?: string;
  initialData?: FormData;
  onError: (message: string) => void;
  onSuccess: (message: string) => void;
}

const StoreForm: React.FC<StoreFormProps> = props => {
  const utils = trpc.useContext();

  const { error, isLoading, data: types } = trpc.useQuery(["types.store"]);

  const { isLoading: isCreating, mutate: createStore } = trpc.useMutation(
    ["store.create"],
    {
      onSuccess: () => handleSuccess("Dyqani u rregjistrua me sukses!"),
      onError: () =>
        handleError("Ndodhi një problem gjatë krijimit të dyqanit."),
    }
  );

  const { isLoading: isEditing, mutate: editStore } = trpc.useMutation(
    ["store.edit"],
    {
      onSuccess: () => handleSuccess("Dyqani u modifikua me sukses!"),
      onError: () =>
        handleError("Ndodhi një problem gjatë modifikimit të dyqanit."),
    }
  );

  // If there are persisted data, use them
  const storedValues = useMemo(() => localStorage.getItem("storeForm"), []);

  const defaultValues = props.initialData
    ? props.initialData
    : storedValues
    ? (JSON.parse(storedValues) as FormData)
    : defaults;

  const {
    watch,
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm<FormData>({
    defaultValues,
    mode: "onBlur",
    criteriaMode: "all",
    resolver: zodResolver(addStoreValidator),
  });

  const formValues = watch();

  // Persist form values to local storage
  useEffect(() => {
    if (!props.storeId)
      localStorage.setItem("storeForm", JSON.stringify(formValues));
  }, [formValues, props.storeId]);

  const handleResetClick = () => !props.storeId && reset({ ...defaults });

  const buttonIcon = Boolean(props.storeId)
    ? "ri-pencil-line"
    : "ri-file-add-line";
  const buttonText = Boolean(props.storeId) ? "Modifiko" : "Regjistro";

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // Since errors are only showed when the field is touched
    // Set all as touched when the user attempts to submit the form
    setValue("name", formValues.name, { shouldTouch: true });
    setValue("type", formValues.type, { shouldTouch: true });
    setValue("nipt", formValues.nipt, { shouldTouch: true });
    setValue("address", formValues.address, { shouldTouch: true });
    handleSubmit(submitCallback)().catch(err => console.error(err));
  }

  const submitCallback = (data: FormData) => {
    Boolean(props.storeId)
      ? editStore({ id: props.storeId as string, ...data })
      : createStore(data);
  };

  async function handleSuccess(message: string) {
    await invalidateDependantQueries();
    handleResetClick();
    localStorage.removeItem("storeForm");
    props.onSuccess(message);
  }

  async function invalidateDependantQueries() {
    if (props.storeId)
      await utils.invalidateQueries(["store.info", { id: props.storeId }]);
    await utils.invalidateQueries(["store.paginated-list"]);
    await utils.invalidateQueries(["store.list"]);
    await utils.invalidateQueries(["store.in-debt"]);
    await utils.invalidateQueries(["store.history"]);
    await utils.invalidateQueries(["store.name"]);
  }

  const handleError = (message: string) => props.onError(message);

  const handleTypeClick = (event: string) =>
    setValue("type", event, { shouldTouch: true });

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-2" noValidate>
      {/* Tipi */}
      <TypePicker
        types={types}
        isLoading={isLoading}
        fetchError={Boolean(error)}
        register={register}
        error={errors.type}
        value={formValues.type}
        touched={touchedFields.type}
        onClick={handleTypeClick}
      />
      {/* NIPT */}
      <TextField
        name="nipt"
        type="text"
        autoComplete="off"
        placeholder="NIPT"
        register={register}
        error={errors.nipt}
        value={formValues.nipt}
        touched={touchedFields.nipt}
      />
      {/* Emri */}
      <TextField
        name="name"
        type="text"
        autoComplete="off"
        placeholder="Emri"
        register={register}
        error={errors.name}
        value={formValues.name}
        touched={touchedFields.name}
      />
      {/* Adresa */}
      <TextField
        name="address"
        type="text"
        autoComplete="off"
        placeholder="Adresa"
        register={register}
        error={errors.address}
        value={formValues.address}
        touched={touchedFields.address}
      />
      <div className="flex items-center justify-center pt-12 gap-4">
        <IconButton
          icon={<i className="ri-restart-line" />}
          disabled={isCreating || isEditing}
          onClick={handleResetClick}
          variant="outlined"
          color="secondary"
        />
        <Button
          loading={isCreating || isEditing}
          prepend={<i className={buttonIcon} />}
          text={buttonText}
          type="submit"
          block
        />
      </div>
    </form>
  );
};

interface Type {
  _id: any;
  created: any;
  type: string;
  name: string;
}

type TypePickerProps = {
  value: string;
  touched?: boolean;
  error?: FieldError;
  register: UseFormRegister<any>;
  onClick: (id: string) => void;

  types?: Type[];
  isLoading: boolean;
  fetchError: boolean;
};

const TypePicker: React.FC<TypePickerProps> = props => {
  const handleEnter = (id: string) => props.onClick(id);

  if (props.isLoading)
    return <div className="h-[70px]">Duke ngarkuar opsionet ...</div>;
  if (props.fetchError)
    return (
      <div className="h-[70px] text-red-500 text-center">
        Ndodhi një problem gjatë ngarkimit të opsioneve
      </div>
    );
  if (!Array.isArray(props.types))
    return <div className="h-[70px]">Nuk u gjend asnjë lloj dyqani!</div>;

  return (
    <RadioSelect
      name="type"
      options={props.types.map(type => ({
        text: type.name,
        value: type._id as string,
      }))}
      error={props.error}
      value={props.value}
      touched={props.touched}
      register={props.register}
      onEnter={handleEnter}
    />
  );
};

export default StoreForm;
