import { useState } from "react";

// tPRC
import trpc from "@utils/trpc";

// React Query
// import { ReactQueryDevtools } from "react-query/devtools";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

// Components
import Router from "./Router";

const URL = process.env.REACT_APP_URL ?? "";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? `Bearer ${token}` : "";
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error && (error as any).message === "Invalid token") {
        localStorage.clear();
        location.reload();
      }
    },
  }),
});

const App = () => {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      url: URL,
      headers() {
        return {
          credentials: "include",
          authorization: getToken(),
        };
      },
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Router />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default App;
