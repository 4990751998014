import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

// css
import "./index.css";
import "remixicon/fonts/remixicon.css";

// Router
import { BrowserRouter } from "react-router-dom";

// Components
import App from "./App";

const container = document.getElementById("app");

if (container) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
}
