function formatAmount(amount: string | number, currency = "ALL"): string {
  const formatter = new Intl.NumberFormat(undefined, {
    currency,
    style: "currency",
    maximumFractionDigits: 2,
  });
  const parsedAmount = parseAmount(amount);
  return formatter.format(parsedAmount);
}

function parseAmount(amount: string | number): number {
  if (typeof amount === "number") return amount;
  return parseFloat(amount.replace(/[^0-9.]/g, ""));
}

export { formatAmount, parseAmount };
