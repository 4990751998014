import React, { useState } from "react";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// tRPC
import trpc from "@utils/trpc";

// Validators
import { useForm } from "react-hook-form";
import { transactionFetchValidator } from "validators/transaction";

// Components
import Dialog from "@components/general/Dialog";
import Button from "@components/general/Button";
import TextField from "@components/general/TextField";
import IconButton from "@components/general/IconButton";
import RadioSelect from "@components/general/RadioSelect";
import DatePicker from "@components/general/date/DatePicker";
import CheckboxGroup from "@components/general/CheckboxGroup";

type FilterSchema = z.infer<typeof transactionFetchValidator>;

type TransactionFilterProps = {
  filters: FilterSchema;
  onChange: (a: FilterSchema) => void;
};

const TransactionFilters: React.FC<TransactionFilterProps> = props => {
  const [show, setShow] = useState(false);

  const toggleDialog = (value: boolean) => setShow(value);

  const handleFilterChange = (filters: FilterSchema) => {
    props.onChange(filters);
    toggleDialog(false);
  };

  return (
    <>
      <TransactionFiltersDialog
        show={show}
        filters={props.filters}
        onConfirm={handleFilterChange}
        onClose={() => toggleDialog(false)}
      />
      <IconButton
        icon={<i className="ri-equalizer-fill" />}
        onClick={() => toggleDialog(true)}
        variant="outlined"
        color="secondary"
      />
    </>
  );
};

interface DialogProps {
  show: boolean;
  filters: FilterSchema;
  onClose: () => void;
  onConfirm: (a: FilterSchema) => void;
}

type sortOrder = "1" | "-1" | undefined;
type sortBy = "amount" | "date" | undefined;

const sortOptions = [
  { value: "date", text: "Data" },
  { value: "amount", text: "Shuma" },
];

const directionOptions = [
  { value: "1", text: "Rritës" },
  { value: "-1", text: "Zbritës" },
];

const TransactionFiltersDialog: React.FC<DialogProps> = props => {
  const { data } = trpc.useQuery(["types.transaction"]);

  const {
    watch,
    reset,
    register,
    setValue,
    formState: { touchedFields, errors },
  } = useForm<FilterSchema>({
    mode: "onBlur",
    criteriaMode: "all",
    resolver: zodResolver(transactionFetchValidator),
    defaultValues: JSON.parse(JSON.stringify(props.filters)) as FilterSchema,
  });

  const formValues = watch();

  function handleTypeChange(value: string) {
    const types = formValues.types ?? [];
    const index = types.findIndex(val => val === value);
    let newValue: string[] = [];
    if (index !== -1) {
      const temp = [...types];
      temp.splice(index, 1);
      newValue = [...temp];
    } else newValue = [...types, value];
    setValue("types", newValue, { shouldTouch: true });
  }

  const handleSortByChange = (event: string) => {
    setValue("sortBy", event as unknown as sortBy, {
      shouldTouch: true,
    });
  };

  const handleSortOrderChange = (event: string) => {
    setValue("sortOrder", event as unknown as sortOrder, { shouldTouch: true });
  };

  const handleClose = () => props.onClose();

  const handleReset = () => {
    reset({
      types: [],
      sortBy: "date",
      sortOrder: "-1",
    });
  };

  const handleApply = () => {
    for (const key of Object.keys(formValues)) {
      if (
        formValues[key] == null ||
        (typeof formValues[key] === "boolean" && key === "types")
      )
        delete formValues[key];
      // Amount
      if (key === "amount") {
        const from = formValues[key]?.from;
        const to = formValues[key]?.to;
        if (!from || !to) delete formValues[key];
        else if (isNaN(from) || isNaN(to)) delete formValues[key];
      }
    }
    props.onConfirm({ ...formValues, store: props.filters.store });
  };

  const handleDateChange = (value: string, key: "from" | "to") => {
    const currentDates = formValues.date ?? { from: "", to: "" };
    setValue(
      "date",
      {
        ...currentDates,
        [key]: value,
      },
      { shouldTouch: true }
    );
  };

  return (
    <Dialog open={props.show} onClose={props.onClose}>
      <div className="w-[500px]">
        {/* Title */}
        <section className="flex justify-between border-b pb-3 border-b-neutral-300">
          <h1 className="text-lg">Filtro Transaksionet</h1>
          <IconButton
            icon={<i className="ri-close-line text-xl" />}
            onClick={props.onClose}
            color="secondary"
            variant="text"
          />
        </section>
        {/* Content */}
        <div className=" max-h-[65vh] overflow-auto">
          <section className="pt-6">
            <h1 className="text-sm">Përfshi këto lloje transaksionesh:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Nëse asnjë lloj nuk është i zgjedhur automatikisht përfshihen të
              gjitha llojet.
            </p>
            <div>
              {data ? (
                <CheckboxGroup
                  name="types"
                  register={register}
                  values={formValues.types ?? []}
                  options={data.map(type => ({
                    text: type.name,
                    value: type._id.toString(),
                  }))}
                  onEnter={handleTypeChange}
                />
              ) : null}
            </div>
          </section>
          <section className="pt-6">
            <h1 className="text-sm">Periudha kohore:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Përcakto cilës periudhë kohore i përkasin transaksionet që doni të
              shfaqen
            </p>
            <div className="flex gap-4">
              <div className="flex-grow">
                <DatePicker
                  error={errors.date}
                  value={formValues.date?.from}
                  onChange={value => handleDateChange(value, "from")}
                />
              </div>
              <div className="flex-grow">
                <DatePicker
                  error={errors.date}
                  value={formValues.date?.to}
                  onChange={value => handleDateChange(value, "to")}
                />
              </div>
            </div>
          </section>
          <section className="pt-6">
            <h1 className="text-sm">Shuma e Transaksionit:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Përcakto cilës shumë i përkasin transaksionet që doni të shfaqen
            </p>
            <div className="flex gap-4">
              <div className="flex-grow">
                <TextField
                  type="number"
                  name="amount.from"
                  autoComplete="off"
                  placeholder="Nga Shuma"
                  register={register}
                  error={errors.amount}
                  value={formValues.amount?.from}
                />
              </div>
              <div className="flex-grow">
                <TextField
                  type="number"
                  name="amount.to"
                  autoComplete="off"
                  placeholder="Deri në shumën"
                  register={register}
                  error={errors.amount}
                  value={formValues.amount?.to}
                />
              </div>
            </div>
          </section>
          <section className="pt-6">
            <h1 className="text-sm">Rëndit sipas:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Përcakto cila fushë do përdoret për renditjen e transaksioneve
            </p>
            <div>
              <RadioSelect
                name="sortBy"
                register={register}
                options={sortOptions}
                error={errors.sortBy}
                value={formValues.sortBy}
                onEnter={handleSortByChange}
                touched={touchedFields.sortBy}
              />
            </div>
          </section>
          <section className="pt-6">
            <h1 className="text-sm">Rendi:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Përcakto nëse rendi do jetë rritës ose zbritës.
            </p>
            <div>
              <RadioSelect
                name="sortOrder"
                register={register}
                options={directionOptions}
                error={errors.sortOrder}
                value={formValues.sortOrder}
                onEnter={handleSortOrderChange}
                touched={touchedFields.sortOrder}
              />
            </div>
          </section>
        </div>

        {/* Actions */}
        <section className="border-t border-neutral-300 flex justify-between pt-3 mt-3">
          <Button
            text="Pastro"
            variant="text"
            color="secondary"
            onClick={handleReset}
          />
          <div className="flex gap-4">
            <Button
              text="Mbyll"
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            />
            <Button
              text="Apliko"
              prepend={<i className="ri-check-line" />}
              onClick={handleApply}
            />
          </div>
        </section>
      </div>
    </Dialog>
  );
};

export default TransactionFilters;
