// Router
import { Outlet } from "react-router-dom";

function AuthLayout() {
  return (
    <div className=" h-screen w-screen bg-gradient-to-b from-amber-800 to-amber-600">
      <Outlet />
    </div>
  );
}

export default AuthLayout;
