// tRPC
import trpc from "@utils/trpc";

// Utilities
import { formatAmount } from "@utils/amount";

// Components
import Card from "@components/general/Card";
import Button from "@components/general/Button";

function Home() {
  const {
    error,
    refetch,
    isLoading,
    data: totals,
  } = trpc.useQuery(["transaction.totals"]);

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  if (isLoading) return <div className="p-4">Duke ngarkuar të dhënat ...</div>;
  if (error)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className=" w-[450px] flex items-center flex-col">
          <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
          <h1 className="text-red-500 pb-6">
            Ndodhi një gabim gjatë ngarkimit të të dhënave.
          </h1>
          <Button
            color="error"
            variant="outlined"
            text="Provo Përsëri"
            onClick={handleRefresh}
            prepend={<i className="ri-restart-line" />}
          />
        </div>
      </div>
    );
  if (!totals) return <div className="p-4">Asnjë e dhënë</div>;

  return (
    <div className="grid grid-cols-3 gap-4 overflow-auto max-h-screen p-4">
      {totals.map(el => (
        <Card
          key={el.type}
          containerClasses="pb-3 pt-1 px-2 col-span-3 lg:col-span-1"
        >
          <div className="p-2">
            <h1>{el.type}</h1>
            <div className="text-center pt-6 text-2xl">
              {formatAmount(el.total)}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default Home;
