import React from "react";

// Components
import Dialog from "@components/general/Dialog";
import Button from "@components/general/Button";
import IconButton from "@components/general/IconButton";

interface ErrorDialog {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const ErrorDialog: React.FC<ErrorDialog> = props => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div>
        {/* Title */}
        <section className="flex justify-between border-b pb-3 border-b-neutral-300">
          <h1 className="text-lg">{props.title}</h1>
          <IconButton
            icon={<i className="ri-close-line text-xl" />}
            onClick={props.onClose}
            color="secondary"
            variant="text"
          />
        </section>
        <section className="pb-6 flex flex-col">
          <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
          <div className="px-2 max-w-md">{props.children}</div>
        </section>
        <section className="border-t border-neutral-300 flex justify-end pt-3 mt-3">
          <Button
            text="Mbyll"
            variant="text"
            color="secondary"
            onClick={() => props.onClose()}
          />
        </section>
      </div>
    </Dialog>
  );
};

export default ErrorDialog;
