import React, { useMemo, useState } from "react";

// Validation
import { FieldError } from "react-hook-form";

// Utilities
import { formatDate } from "@utils/date";

// Components
import Button from "@components/general/Button";
import DateDialog from "@components/general/date/DateDialog";
import { FieldErrors } from "@components/general/TextField";

interface DatePickerProps {
  value?: string;
  error?: FieldError;
  onChange: (d: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = props => {
  const [open, setOpen] = useState(false);

  const buttonText = useMemo(() => {
    if (!props.value) return "Zgjidh Datën";
    return formatDate(new Date(props.value));
  }, [props.value]);

  const toggleDialog = (value: boolean) => setOpen(value);
  const handleSelect = (value: string) => props.onChange(value);

  return (
    <>
      <Button
        onClick={() => toggleDialog(true)}
        text={buttonText}
        prepend={<i className="ri-calendar-event-fill" />}
        variant="outlined"
        color="secondary"
        block
      />
      <FieldErrors error={props.error} touched={true} />
      {open && (
        <DateDialog
          open={open}
          value={props.value}
          onSelect={handleSelect}
          onClose={() => toggleDialog(false)}
        />
      )}
    </>
  );
};

export default DatePicker;
