// Xlsx
import * as XLSX from "xlsx";

// Pdf
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// Zod
import { z } from "zod";

// File saver
import { saveAs } from "file-saver";

// Validation
import { historyOutput, inDebtOutput } from "validators/store";

type InDebtData = z.infer<typeof inDebtOutput>;
type HistoryData = z.infer<typeof historyOutput>;

export function generateHistoryExcel(report: HistoryData) {
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  wb.Props = {
    Title: `${report.name}_${report.nipt}`,
    Subject: "Raport Historik",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(report.nipt);

  const ws = XLSX.utils.json_to_sheet(
    report.transactions.map(transaction => {
      return {
        Data: new Date(transaction.date).toLocaleDateString(),
        Lloji: transaction.type,
        Shuma: transaction.amount,
      };
    })
  );

  wb.Sheets[report.nipt] = ws;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const wb_out = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  saveAs(
    new Blob([s2ab(wb_out as string)], { type: "application/octet-stream" }),
    `${report.name.slice(0, 20)}_${report.nipt}.xlsx`
  );
}

export function generateHistoryPdf(
  report: HistoryData,
  from: string,
  to: string
) {
  const doc = new jsPDF();
  doc.setFontSize(16);
  doc.text(report.name, 14, 14);
  doc.setFontSize(10);
  doc.setTextColor("#525252");
  doc.text(`NIPT: ${report.nipt}`, 14, 20);
  report.address && doc.text(`Adresa: ${report.address}`, 14, 25);
  doc.setTextColor("#000000");
  doc.setFontSize(11);
  doc.text(
    `Periudha: ${new Date(from).toLocaleDateString()} - ${new Date(
      to
    ).toLocaleDateString()}`,
    14,
    40
  );
  autoTable(doc, {
    head: [["Data", "Lloji", "Shuma"]],
    body: report.transactions.map(transaction => {
      return [
        new Date(transaction.date).toLocaleDateString(),
        transaction.type,
        transaction.amount,
      ];
    }),
    margin: { top: 47 },
  });
  doc.save(`${report.name.slice(0, 20)}_${report.nipt}.pdf`);
}

export function generateInDebtExcel(report: InDebtData) {
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  wb.Props = {
    Title: "Lista e Debitorëve",
    Subject: "Lista e Debitorëve",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Debitorët");

  const ws = XLSX.utils.json_to_sheet(
    report.map(transaction => {
      return {
        NIPT: transaction.nipt,
        Emri: transaction.name,
        Debi: transaction.debtAmount,
      };
    })
  );

  wb.Sheets["Debitorët"] = ws;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const wb_out = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  saveAs(
    new Blob([s2ab(wb_out as string)], { type: "application/octet-stream" }),
    `Debitorët_${new Date().toLocaleDateString()}.xlsx`
  );
}

export function generateInDebtPdf(report: InDebtData) {
  const doc = new jsPDF();
  doc.setFontSize(16);
  doc.text("Lista e Debitorëve".toUpperCase(), 78, 14);
  autoTable(doc, {
    head: [["NIPT", "Emri", "Debi"]],
    body: report.map(transaction => {
      return [transaction.nipt, transaction.name, transaction.debtAmount];
    }),
    margin: { top: 37 },
  });
  doc.save(`Debitorët_${new Date().toLocaleDateString()}.pdf`);
}

function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
