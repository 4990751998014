import React from "react";
import { Link, useLocation } from "react-router-dom";

interface PropsInterface {
  url?: string;
  text?: string;
  dense?: boolean;
  onClick?: () => void;
  icon?: React.ReactElement;
}

function MenuItem(props: PropsInterface) {
  let otherClasses = "";
  let focusClasses = "";

  const location = useLocation();

  const baseUrl = `/${location.pathname.split("/")[1]}`;

  if (baseUrl === props.url) {
    otherClasses = "text-white bg-amber-600";
    focusClasses = "focus:outline-amber-800";
  } else {
    otherClasses = "hover:bg-amber-50 text-gray-700 hover:text-black";
    focusClasses = "focus:outline-amber-500 focus:text-amber-500";
  }

  return (
    <li className={`h-12 mb-1 cursor-pointer w-full ${otherClasses}`}>
      {props.url ? (
        <LinkVariant
          url={props.url}
          icon={props.icon}
          text={props.text}
          dense={props.dense}
          classes={focusClasses}
        />
      ) : (
        <ButtonVariant
          text={props.text}
          icon={props.icon}
          dense={props.dense}
          classes={focusClasses}
          onClick={props.onClick}
        />
      )}
    </li>
  );
}

type LinkVariantProps = Pick<
  PropsInterface,
  "url" | "text" | "dense" | "icon"
> & {
  classes: string;
};

function LinkVariant(props: LinkVariantProps) {
  if (!props.url) return null;
  if (props.dense) {
    return (
      <Link
        to={props.url}
        className={`${props.classes} w-full h-full flex items-center justify-center`}
      >
        {props.icon}
      </Link>
    );
  } else {
    return (
      <Link to={props.url} className={`${props.classes} w-full h-full`}>
        <div className="flex items-center h-full w-full gap-5 px-4">
          {props.icon}
          <span>{props.text}</span>
        </div>
      </Link>
    );
  }
}

type ButtonVariantProps = Pick<
  PropsInterface,
  "onClick" | "text" | "dense" | "icon"
> & {
  classes: string;
};

function ButtonVariant(props: ButtonVariantProps) {
  if (!props.onClick) return null;
  if (props.dense)
    return (
      <button
        type="button"
        onClick={props.onClick}
        className={`${props.classes} w-full h-full text-center`}
      >
        {props.icon}
      </button>
    );
  else
    return (
      <button
        type="button"
        onClick={props.onClick}
        className={`${props.classes} w-full h-full text-left`}
      >
        <div className="flex items-center h-full w-full gap-5 px-4 overflow-hidden whitespace-nowrap">
          {props.icon}
          <span className="overflow-hidden">{props.text}</span>
        </div>
      </button>
    );
}

export default MenuItem;
