import React, { useState } from "react";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// tRPC
import trpc from "@utils/trpc";

// Validators
import { useForm } from "react-hook-form";
import { storeFetchValidator } from "validators/store";

// Components
import Dialog from "@components/general/Dialog";
import Button from "@components/general/Button";
import IconButton from "@components/general/IconButton";
import RadioSelect from "@components/general/RadioSelect";
import CheckboxGroup from "@components/general/CheckboxGroup";

type FilterSchema = z.infer<typeof storeFetchValidator>;

type StoreFilterProps = {
  filters: FilterSchema;
  onChange: (a: FilterSchema) => void;
};

const StoreFilters: React.FC<StoreFilterProps> = props => {
  const [show, setShow] = useState(false);

  const toggleDialog = (value: boolean) => setShow(value);

  const handleFilterChange = (filters: FilterSchema) => {
    props.onChange(filters);
    toggleDialog(false);
  };

  return (
    <>
      <StoreFiltersDialog
        show={show}
        filters={props.filters}
        onConfirm={handleFilterChange}
        onClose={() => toggleDialog(false)}
      />
      <IconButton
        icon={<i className="ri-equalizer-fill" />}
        onClick={() => toggleDialog(true)}
        variant="outlined"
        color="secondary"
      />
    </>
  );
};

type DialogProps = {
  show: boolean;
  filters: FilterSchema;
  onClose: () => void;
  onConfirm: (a: FilterSchema) => void;
};

const sortOptions = [
  { value: "name", text: "Emri" },
  { value: "_id", text: "Data e Krijimit" },
  { value: "nipt", text: "NIPT" },
];

const directionOptions = [
  { value: "1", text: "Rritës" },
  { value: "-1", text: "Zbritës" },
];

type sortOrder = "1" | "-1" | undefined;
type sortBy = "name" | "_id" | "nipt" | undefined;

const StoreFiltersDialog: React.FC<DialogProps> = props => {
  const { data } = trpc.useQuery(["types.store"]);

  const {
    watch,
    reset,
    register,
    setValue,
    formState: { touchedFields, errors },
  } = useForm<FilterSchema>({
    mode: "onBlur",
    criteriaMode: "all",
    resolver: zodResolver(storeFetchValidator),
    defaultValues: JSON.parse(JSON.stringify(props.filters)) as FilterSchema,
  });

  const formValues = watch();

  function handleTypeChange(value: string) {
    const types = formValues.types ?? [];
    const index = types.findIndex(val => val === value);
    let newValue: string[] = [];
    if (index !== -1) {
      const temp = [...types];
      temp.splice(index, 1);
      newValue = [...temp];
    } else newValue = [...types, value];
    setValue("types", newValue, { shouldTouch: true });
  }

  const handleSortByChange = (event: string) =>
    setValue("sortBy", event as unknown as sortBy, {
      shouldTouch: true,
    });

  const handleSortOrderChange = (event: string) =>
    setValue("sortOrder", event as unknown as sortOrder, { shouldTouch: true });

  const handleClose = () => props.onClose();

  const handleReset = () => {
    reset({
      types: [],
      sortBy: "_id",
      sortOrder: "-1",
      search: props.filters.search,
    });
  };

  const handleApply = () => {
    for (const key of Object.keys(formValues)) {
      if (
        formValues[key] == null ||
        (typeof formValues[key] === "boolean" && key === "types")
      )
        delete formValues[key];
    }
    delete formValues.search;
    props.onConfirm({
      ...props.filters,
      ...formValues,
    });
  };

  return (
    <Dialog open={props.show} onClose={props.onClose}>
      <div className="w-[500px]">
        {/* Title */}
        <section className="flex justify-between border-b pb-3 border-b-neutral-300">
          <h1 className="text-lg">Filtro Dyqanet</h1>
          <IconButton
            icon={<i className="ri-close-line text-xl" />}
            onClick={props.onClose}
            color="secondary"
            variant="text"
          />
        </section>
        {/* Content */}
        <div className=" max-h-[65vh] overflow-auto">
          <section className="pt-6">
            <h1 className="text-sm">Përfshi këto lloje dyqanesh:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Nëse asnjë lloj nuk është i zgjedhur automatikisht përfshihen të
              gjitha llojet.
            </p>
            <div>
              {data ? (
                <CheckboxGroup
                  name="types"
                  register={register}
                  values={formValues.types ?? []}
                  options={data.map(type => ({
                    text: type.name,
                    value: type._id.toString(),
                  }))}
                  onEnter={handleTypeChange}
                />
              ) : null}
            </div>
          </section>
          <section className="pt-6">
            <h1 className="text-sm">Rëndit sipas:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Përcakto cila fushë do përdoret për renditjen e dyqaneve
            </p>
            <div>
              <RadioSelect
                name="sortBy"
                register={register}
                options={sortOptions}
                error={errors.sortBy}
                value={formValues.sortBy}
                onEnter={handleSortByChange}
                touched={touchedFields.sortBy}
              />
            </div>
          </section>
          <section className="pt-6">
            <h1 className="text-sm">Rendi:</h1>
            <p className="text-xs text-neutral-500 pb-6">
              Përcakto nëse rendi do jetë rritës ose zbritës.
            </p>
            <div>
              <RadioSelect
                name="sortOrder"
                register={register}
                options={directionOptions}
                error={errors.sortOrder}
                value={formValues.sortOrder}
                onEnter={handleSortOrderChange}
                touched={touchedFields.sortOrder}
              />
            </div>
          </section>
        </div>

        {/* Actions */}
        <section className="border-t border-neutral-300 flex justify-between pt-3 mt-3">
          <Button
            text="Pastro"
            variant="text"
            color="secondary"
            onClick={handleReset}
          />
          <div className="flex gap-4">
            <Button
              text="Mbyll"
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            />
            <Button
              text="Apliko"
              prepend={<i className="ri-check-line" />}
              onClick={handleApply}
            />
          </div>
        </section>
      </div>
    </Dialog>
  );
};

export default StoreFilters;
