import React, { FormEvent, useEffect, useMemo } from "react";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// React router
import { useParams } from "react-router-dom";

// tRPC
import trpc from "@utils/trpc";

// Validation
import { addTransactionValidator } from "validators/transaction";
import { FieldError, useForm, UseFormRegister } from "react-hook-form";

// Components
import Button from "@components/general/Button";
import StorePicker from "@components/StorePicker";
import TextField from "@components/general/TextField";
import IconButton from "@components/general/IconButton";
import RadioSelect from "@components/general/RadioSelect";
import DatePicker from "@components/general/date/DatePicker";

type FormData = z.infer<typeof addTransactionValidator>;

const defaults = {
  type: "",
  payed: 0,
  amount: 0,
  notes: "",
  store: "",
  saleNo: "",
  date: new Date().toISOString(),
};

interface TransactionFormProps {
  transactionId?: string;
  initialData?: FormData;
  onError: (message: string) => void;
  onSuccess: (message: string) => void;
}

const TransactionForm: React.FC<TransactionFormProps> = props => {
  const { storeId } = useParams();

  const utils = trpc.useContext();

  const {
    error,
    isLoading,
    data: types,
  } = trpc.useQuery(["types.transaction"]);

  const { isLoading: isCreating, mutate: createTransaction } = trpc.useMutation(
    ["transaction.create"],
    {
      onSuccess: () => handleSuccess("Transaksioni u rregjistrua me sukses!"),
      onError: () =>
        handleError("Ndodhi një problem gjatë krijimit të transaksionit."),
    }
  );

  const { isLoading: isEditing, mutate: editTransaction } = trpc.useMutation(
    ["transaction.edit"],
    {
      onSuccess: () => handleSuccess("Transaksioni u modifikua me sukses!"),
      onError: () =>
        handleError("Ndodhi një problem gjatë modifikimit të transaksionit."),
    }
  );

  // If there are persisted data, use them
  const storedValues = useMemo(
    () => localStorage.getItem("transactionForm"),
    []
  );

  const defaultValues = props.initialData
    ? props.initialData
    : storedValues
    ? (JSON.parse(storedValues) as FormData)
    : defaults;

  // Only when adding a new transaction take into account the storeId passed as a url param
  if (storeId && !props.initialData) defaultValues.store = storeId;

  const {
    watch,
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm<FormData>({
    defaultValues,
    mode: "onBlur",
    criteriaMode: "all",
    resolver: zodResolver(addTransactionValidator),
  });

  const formValues = watch();

  // Persist form values to local storage (only when creating a new transaction)
  useEffect(() => {
    if (!props.transactionId)
      localStorage.setItem("transactionForm", JSON.stringify(formValues));
  }, [formValues, props.transactionId]);

  const handleResetClick = () => !props.transactionId && reset({ ...defaults });

  const isSaleSelected = useMemo(() => {
    if (!types) return false;
    const selected = types.find(
      type => type._id.toString() === formValues.type
    );
    if (!selected) return false;
    else return selected.type === "sale";
  }, [types, formValues.type]);

  const buttonIcon = Boolean(props.transactionId)
    ? "ri-pencil-line"
    : "ri-file-add-line";
  const buttonText = Boolean(props.transactionId) ? "Modifiko" : "Regjistro";

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // Since errors are only showed when the field is touched
    // Set all as touched when the user attempts to submit the form
    setValue("date", formValues.date, { shouldTouch: true });
    setValue("type", formValues.type, { shouldTouch: true });
    setValue("payed", formValues.payed, { shouldTouch: true });
    setValue("notes", formValues.notes, { shouldTouch: true });
    setValue("store", formValues.store, { shouldTouch: true });
    setValue("amount", formValues.amount, { shouldTouch: true });
    setValue("saleNo", formValues.saleNo, { shouldTouch: true });
    handleSubmit(submitCallback)().catch(err => console.error(err));
  }

  async function invalidateDependantQueries() {
    await utils.invalidateQueries(["store.info", { id: formValues.store }]);
    await utils.invalidateQueries(["transaction.paginated-list"]);
    await utils.invalidateQueries(["transaction.totals"]);
  }

  async function handleSuccess(message: string) {
    await invalidateDependantQueries();
    handleResetClick();
    localStorage.removeItem("transactionForm");
    props.onSuccess(message);
  }

  const handleError = (message: string) => props.onError(message);

  const submitCallback = (data: FormData) => {
    Boolean(props.transactionId)
      ? editTransaction({ id: props.transactionId as string, ...data })
      : createTransaction(data);
  };

  const handleDateChange = (date: string) => {
    setValue("date", date, { shouldTouch: true });
  };

  const handleTypeClick = (event: string) =>
    setValue("type", event, { shouldTouch: true });

  const handleStoreChange = (event: string) =>
    setValue("store", event, { shouldTouch: true });

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-2" noValidate>
      {/* Dyqani */}
      <StorePicker
        error={errors.store}
        value={formValues.store}
        onChange={handleStoreChange}
      />
      {/* Data */}
      <DatePicker
        error={errors.date}
        value={formValues.date}
        onChange={handleDateChange}
      />
      <TypePicker
        types={types}
        isLoading={isLoading}
        fetchError={Boolean(error)}
        register={register}
        error={errors.type}
        value={formValues.type}
        touched={touchedFields.type}
        transactionId={props.transactionId}
        onClick={handleTypeClick}
      />
      {/* Shuma */}
      <TextField
        name="amount"
        type="number"
        autoComplete="off"
        placeholder="Shuma"
        register={register}
        error={errors.amount}
        value={formValues.amount}
        touched={touchedFields.amount}
        required
      />
      {isSaleSelected ? (
        <>
          {/* Shuma e Paguar */}
          <TextField
            name="payed"
            type="number"
            autoComplete="off"
            placeholder="Të Paguara"
            register={register}
            error={errors.payed}
            value={formValues.payed}
            touched={touchedFields.payed}
          />
          {/* Fatura */}
          <TextField
            name="saleNo"
            type="text"
            autoComplete="off"
            placeholder="Numri i Faturës"
            register={register}
            error={errors.saleNo}
            value={formValues.saleNo}
            touched={touchedFields.saleNo}
          />
        </>
      ) : null}
      {/* Pershkrimi */}
      <TextField
        name="notes"
        type="text"
        autoComplete="off"
        placeholder="Shënime"
        register={register}
        error={errors.notes}
        value={formValues.notes}
        touched={touchedFields.notes}
      />
      <div className="flex items-center justify-center pt-12 gap-4">
        {Boolean(props.transactionId) ? null : (
          <IconButton
            icon={<i className="ri-restart-line" />}
            disabled={isCreating || isEditing}
            onClick={handleResetClick}
            variant="outlined"
            color="secondary"
          />
        )}
        <Button
          loading={isCreating || isEditing}
          prepend={<i className={buttonIcon} />}
          text={buttonText}
          type="submit"
          block
        />
      </div>
    </form>
  );
};

interface Type {
  _id: any;
  created: any;
  type: string;
  name: string;
}
type TypePickerProps = Pick<TransactionFormProps, "transactionId"> & {
  value: string;
  touched?: boolean;
  error?: FieldError;
  register: UseFormRegister<any>;
  onClick: (id: string) => void;

  types?: Type[];
  isLoading: boolean;
  fetchError: boolean;
};

const TypePicker: React.FC<TypePickerProps> = props => {
  const handleEnter = (id: string) => props.onClick(id);

  if (props.isLoading)
    return <div className="h-[70px]">Duke ngarkuar opsionet ...</div>;
  if (props.fetchError)
    return (
      <div className="h-[70px] text-red-500 text-center">
        Ndodhi një problem gjatë ngarkimit të opsioneve
      </div>
    );
  if (!Array.isArray(props.types))
    return <div className="h-[70px]">Nuk u gjend asnjë lloj transaksioni!</div>;

  return (
    <RadioSelect
      name="type"
      onEnter={handleEnter}
      options={props.types.map(type => ({
        text: type.name,
        value: type._id as string,
      }))}
      error={props.error}
      value={props.value}
      touched={props.touched}
      register={props.register}
      disabled={Boolean(props.transactionId)}
    />
  );
};

export default TransactionForm;
