import { z } from "zod";

const niptLength = 10;

const objectIdValidator = z
  .string({
    invalid_type_error: "Fusha nuk është e vlefshme",
    required_error: "Fusha është e detyrueshme",
  })
  .length(24, "Fusha është e vlefshme");
const niptValidator = z
  .string({
    invalid_type_error: "NIPT-i nuk është i vlefshëm",
    required_error: "NIPT-i është i detyrueshëm",
  })
  .length(niptLength, "NIPT-i duhet të jetë 10 karaktere")
  .trim();

const queryByIdValidator = z.object({
  id: objectIdValidator,
});

const searchValidator = z
  .string({
    invalid_type_error: "Kërkimi nuk është i vlefshëm",
  })
  .max(150, "Kërkimi duhet të jetë maksimum 150 karaktere");

const searchQueryValidator = z.object({
  search: searchValidator,
});

export {
  niptValidator,
  searchValidator,
  objectIdValidator,
  queryByIdValidator,
  searchQueryValidator,
};
