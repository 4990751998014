import React from "react";

// tRPC
import trpc from "@utils/trpc";

// Components
import StoreForm from "@components/StoreForm";
import Dialog from "@components/general/Dialog";
import Button from "@components/general/Button";
import Loading from "@components/general/Loading";
import IconButton from "@components/general/IconButton";

interface DialogProps {
  open: boolean;
  storeId: string;
  onClose: () => void;
  onError: (message: string) => void;
  onSuccess: (message: string) => void;
}

const EditStoreDialog: React.FC<DialogProps> = props => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div className="w-[500px]">
        {/* Title */}
        <section className="flex justify-between pb-3">
          <h1 className="text-lg">Modifiko Dyqanin</h1>
          <IconButton
            icon={<i className="ri-close-line text-xl" />}
            onClick={props.onClose}
            color="secondary"
            variant="text"
          />
        </section>
        {/* Content */}
        <section className="max-h-[80vh] overflow-auto">
          <EditForm
            storeId={props.storeId}
            onClose={props.onClose}
            onError={props.onError}
            onSuccess={props.onSuccess}
          />
        </section>
      </div>
    </Dialog>
  );
};

type EditFormProps = Pick<
  DialogProps,
  "storeId" | "onClose" | "onError" | "onSuccess"
>;

const EditForm: React.FC<EditFormProps> = props => {
  const {
    error,
    refetch,
    isLoading,
    data: store,
  } = trpc.useQuery(["store.info", { id: props.storeId }], {
    enabled: Boolean(props.storeId),
  });

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-col gap-4 py-12">
        <Loading />
        <div className="text-neutral-500">
          Duke ngarkuar të dhënat e dyqanit ...
        </div>
      </div>
    );
  if (error || !store)
    return (
      <div className="flex items-center flex-col py-12">
        <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
        <h1 className="text-red-500 pb-6">
          Ndodhi një gabim gjatë ngarkimit të të dhënave.
        </h1>
        <Button
          color="error"
          variant="outlined"
          text="Provo Përsëri"
          onClick={handleRefresh}
          prepend={<i className="ri-restart-line" />}
        />
      </div>
    );

  function getTransactionData() {
    if (!store) return;
    return {
      name: store.name,
      nipt: store.nipt,
      type: store.type,
      address: store.address,
    };
  }

  const storeData = getTransactionData();

  const handleSuccess = (message: string) => {
    props.onClose();
    props.onSuccess(message);
  };
  const handleError = (message: string) => props.onError(message);

  return (
    <StoreForm
      storeId={props.storeId}
      onError={handleError}
      initialData={storeData}
      onSuccess={handleSuccess}
    />
  );
};

export default EditStoreDialog;
