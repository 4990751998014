import { useState, KeyboardEvent, useId } from "react";

// Validation
import { FieldError, UseFormRegister } from "react-hook-form";

// Components
import { FieldErrors } from "@components/general/TextField";

interface Option {
  text: string;
  value: string;
}

interface RadioSelectProps {
  name: string;
  value?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;

  label?: string;
  error?: FieldError;
  touched?: boolean;
  options?: Option[];
  onEnter?: (a: string) => void;
}

function RadioSelect(props: RadioSelectProps) {
  const [options] = useState(() => props.options ?? []);

  const borderClasses =
    props.error && props.touched ? "border-red-500" : "border-neutral-300";

  const errorClasses = props.error && props.touched ? "text-red-500" : "";

  return (
    <div>
      <fieldset className={errorClasses}>
        <legend className="pb-1 pl-1">{props.label}</legend>
        <div
          className={`flex border rounded-md ${borderClasses} overflow-hidden`}
        >
          {options.map(option => (
            <RadioItem
              name={props.name}
              key={option.value}
              text={option.text}
              value={option.value}
              disabled={props.disabled}
              onEnter={props.onEnter}
              register={props.register}
            />
          ))}
        </div>
      </fieldset>
      <FieldErrors error={props.error} touched={props.touched} />
    </div>
  );
}

type RadioItemProps = Pick<
  RadioSelectProps,
  "name" | "register" | "onEnter" | "disabled"
> &
  Pick<Option, "text" | "value">;

function RadioItem(props: RadioItemProps) {
  const id = useId();
  function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key !== "Enter" || !props.onEnter || props.disabled) return;
    props.onEnter(props.value);
  }

  return (
    <div
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      className="group focus:outline-amber-600 flex-grow bg-white"
    >
      <input
        type="radio"
        value={props.value}
        id={`radio-${id}`}
        disabled={props.disabled}
        className="peer cursor-pointer hidden"
        {...props.register(props.name)}
      />
      <label
        htmlFor={`radio-${id}`}
        className="px-4 py-2 cursor-pointer select-none hover:bg-amber-200 peer-checked:bg-amber-600 peer-checked:text-neutral-100 border-r border-r-neutral-300 group-last:border-r-0 h-10 max-h-10 flex items-center justify-center"
      >
        {props.text}
      </label>
    </div>
  );
}

export default RadioSelect;
