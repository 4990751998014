import React, { useId } from "react";

// React Hook Form
import { FieldError, UseFormRegister } from "react-hook-form";

interface Option {
  text: string;
  value: string;
}

interface CheckboxGroupProps {
  name: string;
  values: string[];
  register: UseFormRegister<any>;

  label?: string;
  error?: FieldError;
  touched?: boolean;
  options?: Option[];
  onEnter?: (a: string) => void;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = props => {
  if (!props.options) return null;

  const handleEnterClick = (value: string) =>
    props.onEnter && props.onEnter(value);

  return (
    <fieldset className="flex flex-col gap-4">
      {props.options.map((option, index) => (
        <Checkbox
          index={index}
          name={props.name}
          key={option.value}
          text={option.text}
          value={option.value}
          register={props.register}
          onEnter={handleEnterClick}
        />
      ))}
    </fieldset>
  );
};

export default CheckboxGroup;

type CheckboxProps = Pick<CheckboxGroupProps, "name" | "register"> &
  Pick<Option, "text" | "value"> & {
    index: number;
    onEnter: (a: string) => void;
  };

const Checkbox: React.FC<CheckboxProps> = props => {
  const id = useId();
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
    event.key === "Enter" && props.onEnter(props.value);

  return (
    <div className="flex items-center">
      <input
        id={id}
        value={props.value}
        onKeyDown={handleKeyDown}
        type="checkbox"
        className="form-check-input appearance-none h-5 w-5 border border-neutral-300 rounded-sm bg-white checked:bg-amber-500 checked:border-amber-700 focus:outline-amber-600 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
        {...props.register(props.name)}
      />
      <label
        htmlFor={id}
        className="flex-grow inline-block text-sm pt-1 cursor-pointer select-none"
      >
        {props.text}
      </label>
    </div>
  );
};
