import { z } from "zod";
import { niptValidator, objectIdValidator } from "./general";

const storeMin = 3;
const storeMax = 100;
const maxSearch = 100;
const addressMax = 200;

const addStoreValidator = z.object({
  nipt: niptValidator,
  type: objectIdValidator,
  address: z
    .string({
      invalid_type_error: "Adresa e dyqanit nuk është e vlefshme",
      required_error: "Adresa e dyqanit është e detyrueshme",
    })
    .max(
      addressMax,
      "Adresa e dyqanit nuk mund të jetë më e gjatë se 200 karaktere"
    )
    .optional(),
  name: z
    .string({
      invalid_type_error: "Emri i dyqanit nuk është i vlefshëm",
      required_error: "Emri i dyqanit është i detyrueshëm",
    })
    .min(
      storeMin,
      "Emri i dyqanit nuk mund të jetë më i shkurtër se 3 karaktere"
    )
    .max(
      storeMax,
      "Emri i dyqanit nuk mund të jetë më i gjatë se 100 karaktere"
    ),
});

const storeFetchValidator = z.object({
  cursor: z.number().nullish(),
  types: z.array(objectIdValidator).optional(),
  search: z
    .string({
      invalid_type_error: "Kërkimi nuk është i vlefshëm",
    })
    .max(maxSearch)
    .optional(),
  sortOrder: z.enum(["1", "-1"]).default("-1").optional(),
  sortBy: z.enum(["name", "nipt", "_id"]).default("_id").optional(),
});

const storeInfoValidator = z.object({
  date: z
    .object({
      from: z.string({
        invalid_type_error: "Segmenti i datës duhet të përbëhet nga dy data",
        required_error: "Limiti i poshtëm i datës është i detyrueshëm",
      }),
      to: z.string({
        invalid_type_error: "Segmenti i datës duhet të përbëhet nga dy data",
        required_error: "Limiti i sipërm i datës është i detyrueshëm",
      }),
    })
    .optional(),
  id: objectIdValidator,
});

const editStoreValidator = z
  .object({
    id: objectIdValidator,
  })
  .merge(addStoreValidator);

// OUTPUTS
const storeInfoOutput = z.object({
  _id: z.string(),
  type: z.string(),
  user: z.string(),
  nipt: z.string(),
  name: z.string(),
  created: z.string(),
  address: z.string(),
  totals: z.array(
    z.object({
      total: z.number(),
      type: z.string(),
    })
  ),
  deleted: z.string().optional(),
  updated: z.string().optional(),
});

const storeFetchOutput = z.array(
  z.object({
    _id: z.string(),
    user: z.string(),
    type: z.object({
      _id: z.string(),
      name: z.string(),
      type: z.string(),
      created: z.string(),
    }),
    name: z.string(),
    nipt: z.string(),
    created: z.string(),
    address: z.string(),
    deleted: z.string().optional(),
    updated: z.string().optional(),
  })
);

const inDebtInput = z.object({
  types: z.array(objectIdValidator).optional(),
});

const inDebtOutput = z.array(
  z.object({
    name: z.string(),
    nipt: z.string(),
    address: z.string(),
    totals: z.array(
      z.object({
        type: z.string(),
        total: z.number(),
      })
    ),
    debtAmount: z.number(),
  })
);

const historyInput = z.object({
  id: objectIdValidator,
  date: z.object({
    from: z.string({
      invalid_type_error: "Segmenti i datës duhet të përbëhet nga dy data",
      required_error: "Limiti i poshtëm i datës është i detyrueshëm",
    }),
    to: z.string({
      invalid_type_error: "Segmenti i datës duhet të përbëhet nga dy data",
      required_error: "Limiti i sipërm i datës është i detyrueshëm",
    }),
  }),
});

const historyOutput = z.object({
  name: z.string(),
  nipt: z.string(),
  address: z.string(),
  transactions: z.array(
    z.object({
      type: z.string(),
      date: z.string(),
      amount: z.number(),
    })
  ),
});

export {
  inDebtInput,
  historyInput,
  inDebtOutput,
  historyOutput,
  storeInfoOutput,
  storeFetchOutput,
  addStoreValidator,
  storeInfoValidator,
  editStoreValidator,
  storeFetchValidator,
};
