import { z } from "zod";
import { objectIdValidator } from "./general";

const addTransactionValidator = z.object({
  store: z
    .string({
      invalid_type_error: "Dyqani nuk është i vlefshëm",
      required_error: "Dyqani është i detyrueshëm",
    })
    .length(24, "Dyqani është i detyrueshëm"),
  type: z
    .string({
      invalid_type_error: "Lloji i transaksionit nuk është i vlefshëm",
      required_error: "Lloji i transaksionit është i detyrueshëm",
    })
    .length(24, "Lloji i transaksionit është i detyrueshëm"),
  payed: z
    .number({
      invalid_type_error: "Shuma nuk është e vlefshme",
    })
    .gte(0, "Shuma nuk mund të jetë negative")
    .optional(),
  amount: z
    .number({
      invalid_type_error: "Shuma nuk është e vlefshme",
      required_error: "Shuma është e detyrueshme",
    })
    .gt(0, "Shuma duhet të jetë më e madhe se 0"),
  saleNo: z
    .string({
      invalid_type_error: "Numri i faturës nuk është f vlefshmëm",
    })
    .max(100, "Numri i faturës nuk mund të jetë më i gjatë se 100 karaktere")
    .optional(),
  notes: z
    .string({
      invalid_type_error: "Komenti nuk është e vlefshme",
    })
    .max(255, "Komenti nuk mund të jetë më i gjatë se 255 karaktere")
    .optional(),
  date: z
    .string({
      invalid_type_error: "Data nuk është e vlefshme",
      required_error: "Data është e detyrueshme",
    })
    .min(24, "Data është e detyrueshme")
    .max(27, "Data është e detyrueshme"),
});

const editTransactionValidator = z
  .object({
    id: objectIdValidator,
  })
  .merge(addTransactionValidator);

const transactionFetchValidator = z.object({
  amount: z
    .object({
      from: z
        .number({
          invalid_type_error:
            "Segmenti i shumës duhet të përbëhet nga dy numra pozitivë",
          required_error: "Limiti i poshtëm i shumës është i detyrueshëm",
        })
        .gte(0, "Shuma duhet të jetë pozitive"),
      to: z
        .number({
          invalid_type_error:
            "Segmenti i shumës duhet të përbëhet nga dy numra pozitivë",
          required_error: "Limiti i sipërm i shumës është i detyrueshëm",
        })
        .gte(0, "Shuma duhet të jetë pozitive"),
    })
    .optional(),
  date: z
    .object({
      from: z.string({
        invalid_type_error: "Segmenti i datës duhet të përbëhet nga dy data",
        required_error: "Limiti i poshtëm i datës është i detyrueshëm",
      }),
      to: z.string({
        invalid_type_error: "Segmenti i datës duhet të përbëhet nga dy data",
        required_error: "Limiti i sipërm i datës është i detyrueshëm",
      }),
    })
    .optional(),
  store: objectIdValidator.optional(),
  types: z
    .array(
      z.string({
        invalid_type_error: "Tipi i transaksionit nuk është i vlefshëm",
        required_error: "Tipi i transaksionit është i detyrueshëm",
      })
    )
    .default([])
    .optional(),
  sortOrder: z.enum(["1", "-1"]).default("-1").optional(),
  sortBy: z.enum(["amount", "date"]).default("date").optional(),
  cursor: z.number().nullish(),
});

// OUTPUTS
const transactionFetchOutput = z.object({
  _id: z.string(),
  date: z.string(),
  store: z.string(),
  user: z.string(),
  amount: z.number(),
  type: z.object({
    _id: z.string(),
    name: z.string(),
    type: z.string(),
    color: z.string(),
    created: z.string(),
  }),
  link: z.string().optional(),
  notes: z.string().optional(),
  saleNo: z.string().optional(),
  deleted: z.string().optional(),
  created: z.string().optional(),
  corrected: z.string().optional(),
});

const transactionsFetchOutput = z.array(transactionFetchOutput);

const transactionTotalsOutput = z.array(
  z.object({
    type: z.string(),
    total: z.number(),
  })
);

export {
  transactionFetchOutput,
  transactionsFetchOutput,
  addTransactionValidator,
  transactionTotalsOutput,
  editTransactionValidator,
  transactionFetchValidator,
};
