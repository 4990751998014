import React, { useCallback, useRef } from "react";

// Zod
import { z } from "zod";

// tRPC
import trpc from "@utils/trpc";

// Validation
import { storeFetchValidator } from "validators/store";

// Navigation
import { useNavigate, useParams } from "react-router-dom";

// Components
import Loading from "@components/general/Loading";

type Filters = z.infer<typeof storeFetchValidator>;

interface StoreListProps {
  filters: Filters;
}

const StoreList: React.FC<StoreListProps> = props => {
  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = trpc.useInfiniteQuery(["store.paginated-list", props.filters], {
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === 0 ? undefined : allPages.length + 1,
  });

  const lastObserver = useRef<IntersectionObserver>();

  const lastElRef = useCallback(
    (node: HTMLDivElement) => {
      if (lastObserver.current) lastObserver.current.disconnect();
      lastObserver.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage().catch(console.error);
        }
      });
      if (node) lastObserver.current.observe(node);
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  const getRef = (groupIndex: number, storeIndex: number) => {
    if (!data) return;
    const isLastGroup = groupIndex === data.pages.length - 1;
    const isLastElement = storeIndex === data.pages[groupIndex].length - 1;
    return isLastGroup && isLastElement ? lastElRef : undefined;
  };

  if (isLoading)
    return (
      <div className="text-center pt-6 uppercase text-neutral-700 text-sm">
        Duke ngarkuar dyqanet ...
      </div>
    );
  if (error)
    return (
      <div className="text-red-500 text-center pt-6">
        Ndodhi një problem gjatë ngarkimit të dyqaneve.
      </div>
    );
  if (!data || !data.pages || !data.pages.length)
    return (
      <div className="text-center pt-6 uppercase text-neutral-700 text-sm">
        Asnjë dyqan.
      </div>
    );

  return (
    <div className="border-t border-neutral-100 h-[calc(100vh-156px)] overflow-auto">
      {data.pages.map((page, pIndex) =>
        page.map((store, sIndex) => (
          <div ref={getRef(pIndex, sIndex)} key={store._id}>
            <Store
              id={store._id}
              name={store.name}
              nipt={store.nipt}
              type={store.type.name}
              address={store.address}
            />
          </div>
        ))
      )}
      {isFetchingNextPage && (
        <div className="flex justify-center pt-3">
          <Loading />
        </div>
      )}
    </div>
  );
};

interface StoreInterface {
  id: string;
  name: string;
  type: string;
  nipt: string;
  address?: string;
}

const Store: React.FC<StoreInterface> = props => {
  const navigate = useNavigate();
  const { storeId } = useParams();

  const handleClick = () => {
    localStorage.setItem("selectedStore", props.id);
    navigate(`/stores/${props.id}`);
  };
  const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) =>
    event.key === "Enter" && handleClick();

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={handleClick}
      onKeyDown={handleKeydown}
      className={`cursor-pointer p-4 h-20 border-b border-neutral-300 focus:outline-amber-700 ${
        storeId === props.id ? "bg-amber-600 text-white" : "hover:bg-amber-100"
      }`}
    >
      <div className="flex justify-between items-center gap-4">
        <h1 className="text-ellipsis overflow-hidden whitespace-nowrap">
          {props.name}
        </h1>
        <h3>{props.nipt}</h3>
      </div>
      <div
        className={`flex justify-between gap-4 items-end pt-1 ${
          storeId === props.id ? "text-white" : "text-neutral-500"
        }`}
      >
        {props.address && (
          <span className="text-sm text-ellipsis overflow-hidden whitespace-nowrap">
            {props.address}
          </span>
        )}
        <span className="flex-shrink-0 border rounded-md text-xs px-2 border-inherit uppercase">
          {props.type}
        </span>
      </div>
    </div>
  );
};

export default StoreList;
