import { useState } from "react";

// Utilities
import { isDateValid } from "@utils/date";

// Components
import Button from "@components/general/Button";
import Dialog from "@components/general/Dialog";
import IconButton from "@components/general/IconButton";
import DateForm from "@components/general/date/DateForm";

type DateDialogProps = {
  open?: boolean;
  value?: string;
  onClose: () => void;
  onSelect: (value: string) => void;
};

const DateDialog: React.FC<DateDialogProps> = props => {
  const [error, setError] = useState("");
  const [localValue, setLocalValue] = useState(props.value);

  const handleConfirmClick = () => {
    if (!localValue) return setError("Zgjidhni një datë");
    const dateObj = new Date(localValue);
    if (!isDateValid(dateObj))
      return setError("Data e zgjedhur nuk është e vlefshme");
    props.onSelect(localValue);
    props.onClose();
  };

  const selectDefaultDate = () => setLocalValue(new Date().toISOString());

  const handleDateChange = (date: string) => setLocalValue(date);

  const handleCloseClick = () => {
    setLocalValue(props.value);
    props.onClose();
  };

  return (
    <Dialog open={props.open ?? false} onClose={handleCloseClick}>
      <>
        <section className="flex justify-between items-center border-b border-b-neutral-300 pb-3">
          <h1 className="text-lg">Data e Transaksionit</h1>
          <IconButton
            variant="text"
            color="secondary"
            icon={<i className="ri-close-line text-xl text-black" />}
            onClick={handleCloseClick}
          />
        </section>
        <section className="w-96 py-4">
          <DateForm value={localValue} onChange={handleDateChange} />
        </section>
        <DateErrors error={error} />
        <section className="flex justify-between pt-4 border-t border-t-neutral-300">
          <Button
            text="Pastro"
            variant="text"
            color="secondary"
            onClick={selectDefaultDate}
          />
          <div className="flex gap-4">
            <Button
              text="Mbyll"
              color="secondary"
              variant="outlined"
              onClick={handleCloseClick}
            />
            <Button
              text="Zgjidh"
              prepend={<i className="ri-calendar-check-line" />}
              onClick={handleConfirmClick}
            />
          </div>
        </section>
      </>
    </Dialog>
  );
};

const DateErrors: React.FC<{ error: string }> = ({ error }) => {
  return (
    <div className={`${error ? "" : "hidden"} text-red-500 px-4 pb-4`}>
      {error}
    </div>
  );
};

export default DateDialog;
