import React, { Fragment } from "react";

// Headless
import { Menu, Transition } from "@headlessui/react";

interface Option {
  text: string;
  icon: string;
  value: string;
}
interface DropdownProps {
  options: Option[];
  children: React.ReactElement;
  onSelect: (a: string) => void;
}

const Dropdown: React.FC<DropdownProps> = props => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button>{props.children}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
          {props.options.map(option => (
            <Menu.Item key={option.value}>
              <button
                onClick={() => props.onSelect(option.value)}
                className="block px-2 py-3 w-full cursor-pointer hover:text-amber-500 hover:bg-amber-100 text-left"
              >
                <i className={`${option.icon} mr-4 pt-1`} />
                <span>{option.text}</span>
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
