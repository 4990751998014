import { z } from "zod";

const usernameMin = 3;
const usernameMax = 30;
const usernameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]*$/;

const passwordMin = 8;
const passwordMax = 30;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).*$/;

const usernameValidator = z
  .string()
  .min(usernameMin)
  .max(usernameMax)
  .regex(usernameRegex);
const passwordValidator = z
  .string()
  .min(passwordMin)
  .max(passwordMax)
  .regex(passwordRegex);

const loginValidator = z.object({
  username: usernameValidator,
  password: passwordValidator,
});

const refreshValidator = z.object({
  token: z.string(),
});

export {
  loginValidator,
  usernameValidator,
  passwordValidator,
  refreshValidator,
};
