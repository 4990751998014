import React, { useState } from "react";

// tRPC
import trpc from "@utils/trpc";

// Components
import Button from "@components/general/Button";
import Dialog from "@components/general/Dialog";
import Loading from "@components/general/Loading";
import IconButton from "@components/general/IconButton";
import TransactionForm from "@components/TransactionForm";
import ConfirmDialog from "@components/general/ConfirmDialog";

interface DialogProps {
  open: boolean;
  storeId: string;
  transactionId: string;
  onClose: () => void;
  onError: (message: string) => void;
  onSuccess: (message: string) => void;
}

const EditTransactionDialog: React.FC<DialogProps> = props => {
  const [showConfirm, setShowConfirm] = useState(false);

  const { isLoading: isDeleting, mutate: deleteTransaction } = trpc.useMutation(
    ["transaction.delete"],
    {
      onSuccess: () => {
        setShowConfirm(false);
        props.onSuccess("Transaksioni u fshi me sukses");
        props.onClose();
      },
      onError: () => {
        setShowConfirm(false);
        props.onSuccess("Ndodhi një problem gjatë fshirjes së transaksionit");
        props.onClose();
      },
    }
  );

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <div className="w-[500px]">
          {/* Title */}
          <section className="flex justify-between pb-3">
            <h1 className="text-lg">Modifiko Transaksionin</h1>
            <IconButton
              icon={<i className="ri-close-line text-xl" />}
              onClick={props.onClose}
              color="secondary"
              variant="text"
            />
          </section>
          {/* Content */}
          <section className="max-h-[80vh] overflow-auto">
            <EditForm
              storeId={props.storeId}
              onClose={props.onClose}
              onError={props.onError}
              isDeleting={isDeleting}
              onSuccess={props.onSuccess}
              transactionId={props.transactionId}
              onDelete={() => setShowConfirm(true)}
            />
          </section>
        </div>
      </Dialog>
      <ConfirmDialog
        open={showConfirm}
        loading={isDeleting}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => deleteTransaction(props.transactionId)}
      >
        <div>Jeni të sigurtë që doni të fshini transaksionin?</div>
      </ConfirmDialog>
    </>
  );
};

type EditFormProps = Pick<
  DialogProps,
  "transactionId" | "storeId" | "onClose" | "onError" | "onSuccess"
> & {
  isDeleting: boolean;
  onDelete: () => void;
};

const EditForm: React.FC<EditFormProps> = props => {
  const {
    error,
    refetch,
    isLoading,
    data: transactions,
  } = trpc.useQuery(["transaction.info", { id: props.transactionId }], {
    enabled: Boolean(props.transactionId),
  });

  const handleRefresh = () => {
    refetch().catch(console.error);
  };

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-col gap-4 py-12">
        <Loading />
        <div className="text-neutral-500">
          Duke ngarkuar të dhënat e transaksionit ...
        </div>
      </div>
    );
  if (error || !Array.isArray(transactions))
    return (
      <div className="flex items-center flex-col py-12">
        <i className="ri-error-warning-line text-[74px] self-center text-red-500" />
        <h1 className="text-red-500 pb-6">
          Ndodhi një gabim gjatë ngarkimit të të dhënave.
        </h1>
        <Button
          color="error"
          variant="outlined"
          text="Provo Përsëri"
          onClick={handleRefresh}
          prepend={<i className="ri-restart-line" />}
        />
      </div>
    );

  function getTransactionData() {
    if (!transactions) return;
    const mainIndex = transactions.findIndex(
      transaction => transaction._id === props.transactionId
    );
    if (mainIndex === -1) return;
    const main = transactions[mainIndex];
    const linked =
      transactions.length === 2 ? transactions[mainIndex === 0 ? 1 : 0] : null;

    return {
      type: main.type._id,
      payed: linked ? linked.amount : 0,
      amount: main.amount,
      notes: main.notes,
      store: main.store,
      saleNo: main.saleNo,
      date: main.date,
    };
  }

  const transactionData = getTransactionData();

  const handleSuccess = (message: string) => {
    props.onClose();
    props.onSuccess(message);
  };
  const handleError = (message: string) => props.onError(message);

  return (
    <>
      <TransactionForm
        onError={handleError}
        onSuccess={handleSuccess}
        initialData={transactionData}
        transactionId={props.transactionId}
      />
      <div className="w-full mt-4">
        <Button
          variant="outlined"
          text="Fshi transaksionin"
          prepend={<i className="ri-delete-bin-7-line" />}
          loading={props.isDeleting}
          onClick={props.onDelete}
          block
        />
      </div>
    </>
  );
};

export default EditTransactionDialog;
