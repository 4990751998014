import React, { useEffect } from "react";

interface SuccessSnackbarProps {
  text: string;
  open: boolean;
  onClose: () => void;
}

const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({
  open,
  text,
  onClose,
}) => {
  // Auto close
  useEffect(() => {
    const timeout = open ? setTimeout(() => onClose(), 2000) : undefined;
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [open, onClose]);

  return (
    <div
      className={`${
        open ? "visible" : "invisible"
      } absolute px-4 py-3 flex items-center gap-4 top-4 left-1/2 -translate-x-1/2 z-20 shadow-md rounded-md bg-green-600 text-white`}
    >
      <i className="ri-check-fill text-2xl" />
      {text}
    </div>
  );
};

export default SuccessSnackbar;
