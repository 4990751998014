import { useState, FormEvent } from "react";

// tRPC
import trpc from "@utils/trpc";

// Zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Validation
import { useForm } from "react-hook-form";
import { loginValidator } from "validators/auth";

// Router
import { useNavigate } from "react-router-dom";

// Components
import Card from "@components/general/Card";
import Button from "@components/general/Button";
import TextField from "@components/general/TextField";
import ErrorDialog from "@components/general/ErrorDialog";

const defaultValues = {
  username: "",
  password: "",
};

type FormData = z.infer<typeof loginValidator>;

type FieldType = "text" | "password";

function Login() {
  const [error, setError] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { touchedFields, errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues,
    mode: "onChange",
    criteriaMode: "firstError",
    resolver: zodResolver(loginValidator),
  });

  const { isLoading, mutate: logIn } = trpc.useMutation(["auth.login"], {
    onSuccess: data => handleSuccess(data),
    onError: () => setError(true),
  });

  const formValues = watch();
  const navigate = useNavigate();

  const [fieldType, setFieldType] = useState<FieldType>("password");

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    handleSubmit(data => logIn(data))().catch(err => console.error(err));
  }

  function handleSuccess(data: { token: string }) {
    localStorage.setItem("token", data.token);
    navigate("/home", { replace: true });
  }

  function toggleFieldType() {
    setFieldType(prev => {
      switch (prev) {
        case "text":
          return "password";
        case "password":
          return "text";
      }
    });
  }

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <Card containerClasses="w-96">
          <div className="py-4">
            <h1 className="text-center text-xl uppercase font-bold tracking-wide">
              Sony.a.d
            </h1>
            <p className="text-center text-neutral-500 leading-none m-0 p-0 pt-1 text-sm">
              Kyçuni në llogarinë tuaj.
            </p>
          </div>
          <form
            onSubmit={onSubmit}
            className="flex flex-col gap-3 p-4 items-center"
          >
            <TextField
              name="username"
              placeholder="Emri"
              error={errors.username}
              value={formValues.username}
              touched={touchedFields.username}
              prepend={<i className="ri-user-line" />}
              register={register}
            />
            <TextField
              name="password"
              placeholder="Fjalëkalimi"
              type={fieldType}
              error={errors.password}
              value={formValues.password}
              touched={touchedFields.password}
              prepend={
                <i
                  className={
                    fieldType === "password"
                      ? "ri-lock-line"
                      : "ri-lock-unlock-line"
                  }
                />
              }
              register={register}
              onPrependClick={toggleFieldType}
            />
            <Button
              loading={isLoading || isSubmitting}
              prepend={<i className="ri-login-box-line" />}
              text="Kyçu në llogari"
              type="submit"
            />
          </form>
        </Card>
      </div>
      <ErrorDialog
        open={error}
        onClose={() => setError(false)}
        title="Veprim i Pasuksesshëm!"
      >
        <span>Ndodhi një problem gjatë kyçjes në aplikacion!</span>
        <br />
        <span>Ju lutemi provni përsëri.</span>
      </ErrorDialog>
    </>
  );
}

export default Login;
