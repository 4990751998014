import React from "react";

// Router
import { Navigate } from "react-router-dom";

interface PropsInterface {
  isPublic?: boolean;
  children: React.ReactElement;
}
const RedirectChecker: React.FC<PropsInterface> = props => {
  const token = localStorage.getItem("token");
  // If accessing not public route and token is not present, redirect to login
  if (!token && !props.isPublic) return <Navigate to="/" replace />;
  // If accessing login when token is present, redirect to home
  else if (token && props.isPublic) return <Navigate to="/home" replace />;
  else return props.children;
};

export default RedirectChecker;
