import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  KeyboardEvent,
} from "react";

// Utilities
import { days, isDateValid, months, getDateValues } from "@utils/date";

// Components
import Button from "@components/general/Button";
import IconButton from "@components/general/IconButton";

interface DateFormProps {
  value?: string;
  onChange: (value: string) => void;
}

type Views = "day" | "month" | "year";

type DateValues = { year: number; month: number; day: number };

// year, month, day - The current year, month, day shown in the picker

// today[Month | Year | Day] - Today's year, month, and day
// selected[Month | Year | Day] - The year, month and day selected in the picker

const TODAY_ISO = new Date().toISOString();
const today = getDateValues(TODAY_ISO);

const DateForm: React.FC<DateFormProps> = props => {
  const selectedDate = getDateFromString(props.value ? props.value : TODAY_ISO);

  const [view, setView] = useState<Views>("day");
  const [date, setDate] = useState(() =>
    getDateFromString(props.value ? props.value : TODAY_ISO)
  );

  function getDateFromString(date?: string) {
    if (!date) throw new Error("Invalid date");
    const dateValues = getDateValues(date);
    if (dateValues) return dateValues;
    else throw new Error("Invalid date");
  }

  function goToPrevious() {
    switch (view) {
      case "day":
        previousMonth();
        break;
      case "month":
        setDate(prev => {
          return { ...prev, year: prev.year - 1 };
        });
        break;
    }
  }

  function goToNext() {
    switch (view) {
      case "day":
        nextMonth();
        break;
      case "month":
        setDate(prev => {
          return { ...prev, year: prev.year + 1 };
        });
        break;
    }
  }

  function previousMonth() {
    if (date.month === 0) {
      setDate(prev => {
        return { ...prev, month: 11, year: prev.year - 1 };
      });
    } else {
      setDate(prev => {
        return { ...prev, month: prev.month - 1 };
      });
    }
  }

  function nextMonth() {
    if (date.month === 11) {
      setDate(prev => {
        return { ...prev, month: 0, year: prev.year + 1 };
      });
    } else {
      setDate(prev => {
        return { ...prev, month: prev.month + 1 };
      });
    }
  }

  function handleViewChange() {
    setView(prev => {
      switch (prev) {
        case "day":
          return "month";
        case "month":
          return "year";
        default:
          return "day";
      }
    });
  }

  function handleSelect(selected: DateValues) {
    const newDate = new Date(selected.year, selected.month, selected.day);
    if (isDateValid(newDate)) {
      const isoDate = newDate.toISOString();
      props.onChange(isoDate);
      setDate(getDateValues(isoDate));
      switch (view) {
        case "year":
          setView("month");
          break;
        case "month":
          setView("day");
          break;
      }
    }
  }

  return (
    <div
      className={`grid ${
        view === "month" ? "grid-cols-3 gap-3" : "grid-cols-7 gap-y-3 gap-x-1"
      } items-center`}
    >
      <div className={`${view === "year" ? "hidden" : ""}`}>
        <IconButton
          icon={<i className="ri-arrow-left-s-line text-2xl" />}
          variant="text"
          color="secondary"
          onClick={goToPrevious}
        />
      </div>
      <div
        className={`${view === "month" ? "" : "col-span-5"} ${
          view === "year" ? "hidden" : ""
        } justify-self-center`}
      >
        <ViewSelector
          view={view}
          day={date.day}
          year={date.year}
          month={date.month}
          onViewChange={handleViewChange}
        />
      </div>
      <div className={`${view === "year" ? "hidden" : ""} justify-self-end`}>
        <IconButton
          icon={<i className="ri-arrow-right-s-line text-2xl" />}
          variant="text"
          color="secondary"
          onClick={goToNext}
        />
      </div>
      <View
        view={view}
        day={date.day}
        year={date.year}
        month={date.month}
        todayDay={today.day}
        todayYear={today.year}
        todayMonth={today.month}
        selectedDay={selectedDate.day}
        selectedYear={selectedDate.year}
        selectedMonth={selectedDate.month}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default DateForm;

interface SelectorProps {
  view: Views;
  day: number;
  year: number;
  month: number;
  onViewChange: () => void;
}

const ViewSelector: React.FC<SelectorProps> = props => {
  const displayText = getDisplayText();

  function getDisplayText(): string {
    let text = "";
    switch (props.view) {
      case "day":
        text = `${months[props.month].name} ${props.year}`;
        break;
      case "month":
        text = props.year.toString();
        break;
    }
    return text;
  }

  return (
    <div>
      <Button
        variant="text"
        color="secondary"
        text={displayText}
        onClick={props.onViewChange}
      />
    </div>
  );
};

interface ViewProps {
  view: Views;
  day: number;
  year: number;
  month: number;
  selectedDay: number;
  selectedYear: number;
  selectedMonth: number;
  todayDay: number;
  todayYear: number;
  todayMonth: number;
  onSelect: (value: DateValues) => void;
}

const View: React.FC<ViewProps> = props => {
  switch (props.view) {
    case "day":
      return <DayView {...props} />;
    case "month":
      return <MonthView {...props} />;
    case "year":
      return <YearView {...props} />;
  }
};

const DayView: React.FC<ViewProps> = props => {
  const daysArr = getDayArray();

  function getDayArray() {
    const daysInMonth = new Date(props.year, props.month + 1, 0).getDate();
    const arr: number[] = [];
    for (let i = 0; i <= daysInMonth - 1; i++) arr.push(i + 1);
    const start = new Date(props.year, props.month, 0).getDay();
    if (start !== 0) padStart(arr, start);
    const end = new Date(props.year, props.month, daysInMonth).getDay();
    if (end !== 6) padEnd(arr, end);
    return arr;
  }

  function padStart(arr: number[], start: number) {
    for (let i = start; i > 0; i--) arr.unshift(0);
  }

  function padEnd(arr: number[], end: number) {
    for (let i = end; i < 6; i++) arr.push(0);
  }

  function handleSelect(day: number) {
    props.onSelect({
      day,
      year: props.year,
      month: props.month,
    });
  }

  const handleKeyDown = (event: KeyboardEvent, day: number) =>
    event.key === "Enter" && handleSelect(day);

  function isSelected(day: number) {
    if (
      day === props.selectedDay &&
      props.selectedMonth === props.month &&
      props.selectedYear === props.year
    )
      return true;
    else return false;
  }

  function selectedStyles(day: number) {
    if (isSelected(day))
      return "bg-amber-500 text-white border focus:bg-amber-700 focus:outline-amber-800";
    else
      return "hover:bg-amber-100 hover:text-amber-500 hover:border hover:border-amber-300 focus:bg-amber-100 focus:outline-amber-300 focus:text-amber-500";
  }

  function isToday(day: number) {
    if (
      day === props.todayDay &&
      props.month === props.todayMonth &&
      props.year === props.todayYear
    )
      return true;
    else return false;
  }

  const hideStyles = (day: number) => (day === 0 ? " select-none hidden" : "");

  const todayStyles = (day: number) =>
    isToday(day) && !isSelected(day)
      ? "border border-amber-500 text-amber-500"
      : "";

  return (
    <>
      {days.map(day => (
        <div
          key={day.abbr}
          className="justify-self-center text-neutral-500 select-none"
        >
          {day.abbr}
        </div>
      ))}
      {daysArr.map((day, index) => (
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleSelect(day)}
          onKeyDown={event => handleKeyDown(event, day)}
          key={`${day}-${index}`}
          className={`${hideStyles(day)} ${selectedStyles(day)} ${todayStyles(
            day
          )} justify-self-center w-10 h-10 rounded-full cursor-pointer flex items-center justify-center select-none`}
        >
          {day === 0 ? "" : day}
        </div>
      ))}
    </>
  );
};

const MonthView: React.FC<ViewProps> = props => {
  function isCurrentMonth(month: number) {
    if (month === props.todayMonth && props.year === props.todayYear)
      return true;
    else return false;
  }

  function isSelectedMonth(month: number) {
    if (
      month === props.selectedMonth &&
      props.selectedYear === props.selectedYear
    )
      return true;
    else return false;
  }

  function selectedStyles(month: number) {
    if (isSelectedMonth(month))
      return "bg-amber-500 text-white border-amber-700 focus:bg-amber-700 focus:outline-amber-800";
    else
      return "hover:bg-amber-100 hover:text-amber-500 hover:border-amber-300 focus:bg-amber-100 focus:outline-amber-300 focus:text-amber-500";
  }

  const currentStyles = (month: number) =>
    isCurrentMonth(month) && !isSelectedMonth(month)
      ? "border-amber-500 text-amber-500"
      : "border-neutral-400";

  function handleSelect(month: number) {
    props.onSelect({
      month,
      year: props.year,
      day: props.selectedDay,
    });
  }

  const handleKeyDown = (event: KeyboardEvent, month: number) =>
    event.key === "Enter" && handleSelect(month);

  return (
    <>
      {months.map((month, index) => (
        <div
          role="button"
          tabIndex={0}
          key={month.abbr}
          onClick={() => handleSelect(index)}
          onKeyDown={event => handleKeyDown(event, index)}
          className={`${currentStyles(index)} ${selectedStyles(
            index
          )} justify-self-center w-full h-14 flex items-center justify-center rounded-md cursor-pointer select-none border`}
        >
          {month.name}
        </div>
      ))}
    </>
  );
};

function getInitialRange(year: number) {
  const arr: number[] = [];
  for (let i = 25; i >= -25; i--) {
    arr.push(year + i);
  }
  return arr;
}

function loadPreviousYears(years: number[]) {
  const arr = years.slice(25, years.length);
  const earliestYear = years[years.length - 1];
  for (let i = 1; i <= 50; i++)
    if (earliestYear - i >= 1) arr.push(earliestYear - i);
  return arr;
}

function loadNextYears(years: number[]) {
  const arr = years.slice(0, years.length - 25);
  const latestYear = years[0];
  for (let i = 1; i <= 50; i++) arr.unshift(latestYear + i);
  return arr;
}

const YearView: React.FC<ViewProps> = props => {
  const lastObserver = useRef<IntersectionObserver>();
  const firstObserver = useRef<IntersectionObserver>();
  const [years, setYears] = useState<number[]>(() =>
    getInitialRange(props.selectedYear)
  );
  const [hasMore, setHasMore] = useState(() => years[0] > 1);

  const firstElRef = useCallback((node: HTMLDivElement) => {
    if (firstObserver.current) firstObserver.current.disconnect();
    firstObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) setYears(prev => loadNextYears(prev));
    });
    if (node) firstObserver.current.observe(node);
  }, []);

  const lastElRef = useCallback(
    (node: HTMLDivElement) => {
      if (lastObserver.current) lastObserver.current.disconnect();
      lastObserver.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          let arr: number[] = [];
          setYears(prev => {
            arr = loadPreviousYears(prev);
            return arr;
          });
          if (arr[0] === 1) setHasMore(false);
        }
      });
      if (node) lastObserver.current.observe(node);
    },
    [hasMore]
  );

  useEffect(() => {
    const selectedElement = document.getElementById(`${props.selectedYear}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({ block: "center" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isCurrentYear(year: number) {
    if (year === props.todayYear) return true;
    else return false;
  }

  function isSelectedYear(year: number) {
    if (year === props.selectedYear) return true;
    else return false;
  }

  function selectedStyles(year: number) {
    if (isSelectedYear(year))
      return "bg-amber-500 text-white border-amber-700 focus:bg-amber-700 focus:outline-amber-800";
    else
      return "hover:bg-amber-100 hover:text-amber-500 hover:border-amber-300 focus:bg-amber-100 focus:outline-amber-300 focus:text-amber-500";
  }

  const currentStyles = (day: number) =>
    isCurrentYear(day) && !isSelectedYear(day)
      ? "border-amber-500 text-amber-500"
      : "border-neutral-400";

  function handleSelect(year: number) {
    props.onSelect({
      year,
      day: props.selectedDay,
      month: props.selectedMonth,
    });
    setYears(getInitialRange(year));
  }

  function getRef(index: number) {
    if (index === 0) return firstElRef;
    else if (index === years.length - 1) return lastElRef;
  }

  const handleKeyDown = (event: KeyboardEvent, day: number) =>
    event.key === "Enter" && handleSelect(day);

  return (
    <ol className="justify-self-center col-span-7 w-full max-h-60 overflow-auto">
      {years.map((year, index) => (
        <li className="mb-3 last-of-type:mb-0" key={year}>
          <div
            role="button"
            tabIndex={0}
            id={year.toString()}
            ref={getRef(index)}
            onClick={() => handleSelect(year)}
            onKeyDown={event => handleKeyDown(event, year)}
            className={`${selectedStyles(year)} ${currentStyles(
              year
            )} h-12 flex items-center justify-center rounded-md cursor-pointer select-none border`}
          >
            {year}
          </div>
        </li>
      ))}
    </ol>
  );
};
